import {
  Card,
  CardContent,
} from '@mui/material';
import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const AddTodoTask = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
   <Card
  sx={{  shadow: 2, dropShadow: 5, border: '1px solid #B0BEC5', minWidth: 275, width: 300, height: 225, cursor: 'pointer', backgroundColor: '#D1E9F6', borderRadius: 2, boxShadow: 3, position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
>
  
  <CardContent sx={{ textAlign: 'center'}}>
  <AddCircleOutlineIcon sx={{ fontSize: 70, color: '#000000', opacity: 0.3 }}/>
  </CardContent>
  {/* {isExpanded && (
    <CardContent sx={{ borderTop: '1px solid #B0BEC5', pt: 2 }}>
      <Typography variant="body2">
        Additional details about the task can be entered here. Include more information, context, or instructions as needed.
      </Typography>
      <Button size="small" onClick={handleEdit}>
        Edit
      </Button>
    </CardContent>
  )} */}
</Card>

    </div>
  );
};

export default AddTodoTask;
