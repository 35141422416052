// src/App.tsx
import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './components/Auth/Login';
import Navbar from './components/Navbar/navbar';
import routes from './router';
import { Provider } from 'react-redux';
import store from './redux/store';
import fetchWrapper, { setNavigateCallback } from './fetchWrapper';

interface PrivateRouteProps {
  element: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const navigate = useNavigate();

  useEffect(() => {
    setNavigateCallback((path) => {
      console.log('Navigating to:', path);
      if (path === '/login') {
        localStorage.removeItem('user');
        navigate('/login');
      } else if (path === '/logout') {
        localStorage.removeItem('user');
        navigate('/login');
      }
      navigate(path);
    });
  }, [navigate]);

  if (!user) {
    // Redirect to login if the user is not logged in
    return <Navigate to="/login" />;
  }

  // Render the protected route
  return element;
};

const App: React.FC = () => {
  const [isLoggedIn, setLoggedIn] = useState(
    Boolean(localStorage.getItem('user'))
  );

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setLoggedIn(false);
  };

  return (
    <Provider store={store}>
      <Routes>
        {/* Define the login route */}
        <Route path="/login" element={<Login onLogin={handleLogin} />} />

        {/* Use a layout for protected routes */}
        <Route
          path="/*"
          element={
            <PrivateRoute
              element={
                <>
                  <Navbar onLogout={handleLogout} />
                  <Routes>
                    {routes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={route.element}
                      />
                    ))}
                    {/* Add more routes as needed */}
                  </Routes>
                </>
              }
            />
          }
        />
      </Routes>
    </Provider>
  );
};

export default App;
