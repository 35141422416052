import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  IconButton,
  Switch,
  TableSortLabel,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmployeesForm from '../EmployeesForm/employeesForm';
import { useDispatch, useSelector } from 'react-redux';
import { selectValue, updateValue } from 'src/redux/reducers/myReducer';
import {
  deleteEmployee,
  getAllEmployees,
  updateStatus
} from 'src/services/employeeService';
import Swal from 'sweetalert2';
import { BarLoader } from 'react-spinners';
// import Loader from '../loader';

const columns = [
  { id: 'firstName', label: 'First Name', minWidth: 25 },
  { id: 'lastName', label: 'Last Name', minWidth: 25 },
  { id: 'email', label: 'Email Address', minWidth: 40, align: 'right' },
  { id: 'status', label: 'Status', minWidth: 30, align: 'right' },
  { id: 'actions', label: 'Actions', minWidth: 30, align: 'right' }
];

const Employees = () => {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeFormAction, setEmployeeFormAction] = useState(null);
  const dispatch = useDispatch();
  const value = useSelector(selectValue);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');

  const handleClick = () => {
    dispatch(updateValue('New Value from Component A'));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    getEmployees();
  };

  const handleEdit = (index) => {
    setSelectedEmployee(employees[index]);
    setEmployeeFormAction('edit');
    setIsModalOpen(true);
  };

  const handleDelete = async (index) => {
    setLoading(true);
    const response = await deleteEmployee(employees[index].userId);
    if (response) {
      await getEmployees();
      setLoading(false);
      Swal.fire({
        text: 'Successfully deleted',
        icon: 'success',
        confirmButtonText: 'Close!'
      });
    }
  };

  const getEmployees = async () => {
    setLoader(true);
    const employeesResponse = await getAllEmployees();
    setEmployees(employeesResponse.data);
    setLoader(false);
  };

  const updateEmployeeStatus = async (id, status) => {
    const updatedEmployees = employees.map((employee) => {
      if (employee.userId === id) {
        return { ...employee, status: status };
      }
      return employee;
    });
    setEmployees(updatedEmployees);
    const response = await updateStatus(id, status);
    if (response) {
      await getEmployees();
    }
  };

  const handleSort = (columnId: any) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const sortedEmployees = () => {
    if (orderBy === '') {
      return employees;
    }
    return employees.slice().sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return (order === 'asc' ? 1 : -1) * aValue.localeCompare(bValue);
      }
      return (order === 'asc' ? 1 : -1) * (aValue - bValue);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getEmployees();
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <Container sx={{ mt: 5, mx: '8vw' }}>
      {loader && (
        <div className="loader-wrapper" style={{ textAlign: 'center' }}>
          <BarLoader color={'#1976D2'} loading={loader} width={'82vw'} />
        </div>
      )}
      <Box display={'flex'} justifyContent={'center'}>
        <Typography variant="h4" color={'#1976D2'} width={'82vw'}>
          EMPLOYEES
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} width={'84vw'}>
        <Button
          variant="outlined"
          sx={{
            padding: '10px',
            scale: '0.9',
            boxShadow: ' 0 4px 8px rgba(0, 0, 0, 0.1)',
            '&:hover': {
              transform: 'scale(0.9)',
              transition: 'all 0.3s ease'
            }
          }}
          onClick={openModal}
        >
          Add Employee
        </Button>
      </Box>
      <Box mt={3}>
        <Paper sx={{ width: '84vw', overflow: 'hidden' }} elevation={3}>
          <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align as any}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: '800',
                        color: '#1976D2'
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={
                          orderBy === column.id ? order : ('asc' as any)
                        }
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedEmployees()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell>{row['firstName']}</TableCell>
                      <TableCell>{row['lastName']}</TableCell>
                      <TableCell align="right">{row['email']}</TableCell>
                      <TableCell align="right">
                        <Switch
                          checked={row.status}
                          onChange={(event) =>
                            updateEmployeeStatus(
                              row.userId,
                              event.target.checked
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleEdit(index)}
                          style={{ color: '#1976D2', cursor: 'pointer' }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(index)}
                          style={{ color: '#1976D2', cursor: 'pointer' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={employees.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <EmployeesForm
        open={isModalOpen}
        onClose={closeModal}
        employee={selectedEmployee}
        action={employeeFormAction}
      />
      {/* {loader && <BarLoader loading={loader} />} */}
    </Container>
  );
};

export default Employees;
