import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  TableSortLabel,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import ClientsForm from '../ClientsForm/clientsForm';
import { useSelector } from 'react-redux';
import { selectValue } from 'src/redux/reducers/myReducer';
import {
  getAllClients,
  getClientById,
  updateClient
} from 'src/services/clientService';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import DeleteIcon from '@mui/icons-material/Delete';
import ViewClient from '../viewClient/viewClient';
// import Loader from '../loader';
import { BarLoader } from 'react-spinners';
import SearchBarWithCheckbox from '../HelperComponents/SearchBarWithCheckbox';
import { formatClientId } from 'src/utils/helperFunctions';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columnsForSearchbar: Column[] = [
  { id: 'state', label: 'State', minWidth: 150 },
  { id: 'clientId', label: 'Client ID', minWidth: 100 },
  { id: 'federalTaxId', label: 'Federal Tax ID', minWidth: 150 },
  { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 200 },
  { id: 'doingBusinessAs', label: 'Doing Business As', minWidth: 150 },
  { id: 'contactPerson', label: 'Contact Person', minWidth: 150 },
  { id: 'contactEmail', label: 'Contact Email', minWidth: 150 },
  { id: 'contactNumber', label: 'Contact Number', minWidth: 150 },
  { id: 'accountantName', label: 'Accountant', minWidth: 150 }
];

const columns: Column[] = [
  { id: 'state', label: 'State' },
  { id: 'clientId', label: 'Client ID' },
  { id: 'federalTaxId', label: 'Federal Tax ID' },
  { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 200 },
  {
    id: 'doingBusinessAs',
    label: 'Doing Business As',
    minWidth: 150
  },
  {
    id: 'contactPerson',
    label: 'Contact Person'
  },
  {
    id: 'contactEmail',
    label: 'Contact Email'
  },
  {
    id: 'contactNumber',
    label: 'Contact Number'
  },
  {
    id: 'accountantName',
    label: 'Accountant'
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 30,
    align: 'right'
  }
];

interface Data {
  state: string;
  legalBusinessName: string;
  doingBusinessAs: string;
  contactPerson: string;
  contactEmail: string;
  contactNumber: string;
}

function createData(data: any): any {
  return data;
}

const Clients = () => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [action, setAction] = useState('add');
  const [client, setClient] = useState(null);
  const [unFilteredClient, setUnFilteredClient] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'Legal Business Name',
    'Doing Business As'
  ]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState('All');


  const value = useSelector(selectValue);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getClients = async () => {
    try {
      setIsLoading(true);
      const data = await getAllClients();
      console.log('data::', data);

      // Create an array of Data objects using the fetched data
      const clientRows = data.data.map((item: any) => {
        return createData(item);
      });

      // Update the rows state with the new data
      setRows(clientRows);
      console.log({ clientRows });
      setUnFilteredClient(clientRows);
      if (data && data.data > 0 || unFilteredClient.length > 0) {
        handleFilter(selectedColumns, searchValue);
      }
      setIsLoading(false); // Set loader to false after successful fetching
    } catch (error) {
      setIsLoading(false); // Ensure loader is set to false on error
      console.error('Error fetching clients:', error);
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const openViewModal = () => {
    setIsViewModalOpen(true);
  };
  const closeModal = async () => {
    setIsModalOpen(false);
    await getClients();
  };
  const closeViewModal = () => {
    setIsViewModalOpen(false);
    setClient(null);
  };
  const handleEdit = async (id: number) => {
    setAction('edit');
    try {
      setIsLoading(true);
      const client = await getClientById(id);
      if (client) {
        setClient(client.data);
        setIsLoading(false);
        openModal();
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
      setIsLoading(false);
    }
  };
  const handleView = async (id: number) => {
    try {
      setIsLoading(true);
      const client = await getClientById(id);
      if (client) {
        setClient(client.data);
        setIsLoading(false);
        setIsViewModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
      setIsLoading(false);
    }
  };
  const handleAddClient = async () => {
    setAction('add');
    setClient(null);
    openModal();
  };

  const handleDelete = async (id: number) => {
    //  const response = await deleteClient(id)
    const response: any = await updateClient(id, { isDeleted: true });
    console.log('response::', response);

    if (response.ok) {
      await getClients();
    }

    console.log('index::', id);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await getClients();
    };

    // Call the fetch function
    fetchInitialData();
  }, []);

  //filtring
  const sortTableDataByColumnsAndSearch = (
    data: any,
    selectedColumns: string[],
    searchValue: string
  ) => {
    console.log({ data });
    console.log({ selectedColumns });
    console.log({ searchValue });

    // if (searchValue.trim() === '' || !selectedColumns.length) {
    //   // Return all data if searchValue is empty
    //   return data;
    // }

    const filteredData = data.filter((item: any) => {
      let isMatch = false;
      searchValue = searchValue.toLocaleLowerCase();
      selectedColumns.forEach((column) => {
        switch (column) {
          case 'Client ID':
            if (item.clientId?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'State':
            if (item.state?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Legal Business Name':
            if (item.legalBusinessName?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Doing Business As':
            if (item.doingBusinessAs?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Federal Tax ID':
            if (item.federalTaxId?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Contact Person':
            if (item.contactPerson?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Contact Email':
            if (item.contactEmail?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Contact Number':
            if (item.contactNumber?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Accountant':
            if (item.accountantName?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          // Add cases for other columns as needed
          default:
            break;
        }
      });

      return isMatch;
    });
    console.log({ filteredData });

    return filteredData;
  };

  // const sortTableDataByColumnsAndSearch = (
  //   data: any[],
  //   selectedColumns: string[],
  //   searchValue: string
  // ) => {
  //   console.log({ data });
  //   console.log({ selectedColumns });
  //   console.log({ searchValue });

  //   // Ensure searchValue is trimmed and lowercase only once
  //   const searchValueLower = searchValue.trim().toLowerCase();

  //   // If searchValue is empty or no columns are selected, return all data
  //   if (searchValueLower === '' || selectedColumns.length === 0) {
  //     return data;
  //   }

  //   const filteredData = data.filter((item: any) => {
  //     let isMatch = false;
  //     // Loop through selectedColumns
  //     selectedColumns.forEach((column) => {
  //       // Check if the column exists in the item and matches the search value
  //       if (item[column]?.toLowerCase().includes(searchValueLower)) {
  //         isMatch = true;
  //         // Exit forEach loop once a match is found in any column
  //         return;
  //       }
  //     });
  //     return isMatch;
  //   });

  //   console.log({ filteredData });

  //   return filteredData;
  // };

  const handleFilter = (selectedColumns: string[], searchValue: string) => {
    let clients = unFilteredClient || []; // Ensure clients is an array even if unFilteredClient is null or undefined

    if (selectedStatus === 'Active') {
        clients = clients.filter(client => !client.inactive);
    } else if (selectedStatus === 'Inactive') {
        clients = clients.filter(client => client.inactive);
    }

    if (searchValue === '') {
        setRows(clients);
        return;
    }

    const filteredData = sortTableDataByColumnsAndSearch(clients, selectedColumns, searchValue);
    setRows(filteredData);
};

  const handleExportClients = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Define columns
    worksheet.columns = [
      { header: 'S.No', key: 'sno', width: 6 },
      { header: 'State', key: 'state', width: 16 },
      { header: 'Client Id', key: 'id', width: 6 },
      { header: 'Federal Tax ID', key: 'federalTaxId', width: 18 },
      { header: 'Legal Business Name', key: 'legalBusinessName', width: 30 },
      { header: 'Doing Business As', key: 'doingBusinessAs', width: 30 },
      { header: 'Contact person', key: 'contactPerson', width: 18 },
      { header: 'Contact Email', key: 'contactEmail', width: 18 },
      { header: 'Contact Number', key: 'contactNumber', width: 18 },
      { header: 'Accountant', key: 'accountantName', width: 18 }
    ];

    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    // Add rows
    rows.map((row) => {
      worksheet.addRow({
        sno: rows.indexOf(row) + 1,
        state: row.state,
        id: formatClientId(+row.id),
        federalTaxId: row.federalTaxId || '',
        legalBusinessName: row.legalBusinessName || '',
        doingBusinessAs: row.doingBusinessAs || '',
        contactPerson: row.contactPerson || '',
        contactEmail: row.contactEmail || '',
        contactNumber: row.contactNumber || '',
        accountantName: row.accountantName || ''
      });
    });

    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    saveAs(blob, 'export.xlsx');
  };

  const handleSort = (columnId: any) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const handleStatusChange = (value: string) => {
    setSelectedStatus(value);
  };
  const sortedClient = () => {
    if (orderBy === '') {
      return client;
    }
    return client.slice().sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return (order === 'asc' ? 1 : -1) * aValue.localeCompare(bValue);
      }
      return (order === 'asc' ? 1 : -1) * (aValue - bValue);
    });
  };

  useEffect(() => {
    handleFilter(selectedColumns, searchValue);
  }, [selectedStatus]);

  return (
    <Box sx={{ mt: 1 }}>
      {/* <Box> <div>
      <p>Value in Component B: {value}</p>
    </div></Box> */}
      {isLoading && (
        <div
          className="loader-wrapper"
          style={{ textAlign: 'center', marginLeft: '1vw', marginRight: '1vw' }}
        >
          <BarLoader color={'#1976D2'} loading={isLoading} width={'96vw'} />
        </div>
      )}

      <Box
        sx={{ mt: 1 }}
        display={'flex'}
        justifyContent={'center'}
        width={'84vw'}
      >
        <Typography variant="h4" color={'#1976D2'}>
          CLIENTS
        </Typography>
      </Box>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} sx={{ mt: { xs: 1, md: 1 } }}>
            <Box
          display="flex"
          justifyContent="flex-start"
          width={{ xs: '56', sm: '42vw', md: '42vw' }}
          marginTop={'15px'}
          marginRight={'5px'}
        >
          <SearchBarWithCheckbox
            columns={columnsForSearchbar}
            onSearch={handleFilter}
            value={searchValue}
            scolumns={selectedColumns}
            updateSearchValue={setSearchValue}
            updateSelectedColumns={setSelectedColumns}
          />
        </Box>
            </Grid>
            <Grid item xs={6} md={2} sx={{ mt: { xs: 0, md: 0 } }}>
            <InputLabel htmlFor="client">Status</InputLabel>
              <Autocomplete
                id="client"
                options={['All', 'Active', 'Inactive']}
                // getOptionLabel={(client) => client.legalBusinessName || ''}
                value={selectedStatus}
                onChange={(_, newValue) => handleStatusChange(newValue)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </Grid>
            <Grid
              item
              xs={4}
              md={1.5}
              sx={{ mt: { xs: 0, md: 2 }, scale: { xs: 0.6 } }}
            >
              <Button
            variant="outlined"
            sx={{
              padding: '10px',
              scale: '0.9',
              boxShadow: ' 0 4px 8px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                transform: 'scale(0.9)',
                transition: 'all 0.3s ease'
              }
            }}
            onClick={handleAddClient}
          >
            Add Client
          </Button>
            </Grid>
            <Grid
              item
              xs={5}
              md={1}
              sx={{ mt: { xs: 0, md: 2 }, scale: { xs: 0.6 } }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={handleExportClients}
              >
                Export
                {/* <FileDownloadIcon /> */}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      <Box mt={3} sx={{ mx: 0, width: '98vw', overflow: 'hidden' }}>
        <Paper sx={{ width: '100%', overflow: 'auto' }} elevation={3}>
          <TableContainer style={{ marginLeft: '0px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: '800',
                        color: '#1976D2',
                        border: '1px solid #E3E1D9',
                        borderCollapse: 'collapse',
                        padding: 5
                      }}
                    >
                      {/* {column.label} */}

                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={
                          orderBy === column.id ? order : ('asc' as any)
                        }
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          if (column.id === 'clientId') {
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                sx={{ border: '1px solid #f1f1f1', padding: 1 }}
                              >
                                {column.format && typeof +row['id'] === 'number'
                                  ? column.format(formatClientId(+row['id']))
                                  : formatClientId(+row['id'])}
                              </TableCell>
                            );
                          } else if (column.id !== 'actions') {
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                sx={{ border: '1px solid #f1f1f1', padding: 1 }}
                              >
                                {column.format &&
                                typeof row[column.id] === 'number'
                                  ? column.format(row[column.id])
                                  : row[column.id]}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                sx={{ border: '1px solid #f1f1f1', padding: 1 }}
                              >
                                <VisibilityIcon
                                  onClick={() => handleView(row.id)}
                                  style={{
                                    color: '#1976D2',
                                    cursor: 'pointer'
                                  }}
                                />
                                <EditIcon
                                  onClick={() => handleEdit(row.id)}
                                  style={{
                                    color: '#1976D2',
                                    cursor: 'pointer',
                                    marginLeft: '8px'
                                  }}
                                />
                                {/* <DeleteIcon onClick={() => handleDelete(row.id)} style={{ color: '#1976D2', marginLeft: '8px', cursor: 'pointer' }} /> */}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100, 150, 200]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <ClientsForm
        open={isModalOpen}
        onClose={closeModal}
        client={client}
        action={action}
      />
      <ViewClient
        open={isViewModalOpen}
        onClose={closeViewModal}
        client={client}
      />
      {/* {isLoading && <Loader loading={isLoading} />} */}
    </Box>
  );
};

export default Clients;
