import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BarLoader } from 'react-spinners';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import { getEmployeeReport } from 'src/services/payrollService';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const columns = [
  { id: 'sno', label: 'S.No' },
  { id: 'state', label: 'State' },
  { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 150 },
  { id: 'monthYear', label: 'Month/Year' },
  { id: 'totalGrossWages', label: 'Gross Wages' },
  { id: 'totalFederalWH', label: 'Federal WH' },
  { id: 'totalStateWH', label: 'State WH' }
];

const summaryColumns = [
  { id: 'index', label: 'S.No' },
  { id: 'state', label: 'State' },
  { id: 'legalBusinessName', label: 'Business Name', minWidth: 150 },
  { id: 'grossWages', label: 'Gross Wage' },
  { id: 'federalWH', label: 'Federal WTH' },
  { id: 'stateWH', label: 'State WTH' }
];
const EmployeeReport = () => {
  const [loading, setLoading] = useState(false);
  const [selectedStartMonth, setSelectedStartMonth] = useState(null);
  const [selectedEndMonth, setSelectedEndMonth] = useState(null);
  const [federalTaxId, setFederalTaxId] = useState('');
  const [empName, setEmpName] = useState('');
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(100);
  const [employeeReports, setEmployeeReports] = useState([]);
  const [rows, setRows] = useState([]);
  const [reportType, setReportType] = useState('Detailed');

  const styles = {
    cell: {
      border: '1px solid #E3E1D9',
      borderCollapse: 'collapse',
      padding: '6px'
    },
    cellTotal: {
      border: '1px solid #E3E1D9',
      borderCollapse: 'collapse',
      padding: '6px',
      fontWeight: 'bold'
    }
  };

  const handleReportTypeChange = (event) => {
    setReportType(event.target.value);
  };

  const calculateTotal = (key) => {
    return rows
      .reduce(
        (sum, item) =>
          sum +
          item.payrolls.reduce(
            (innerSum, payroll) =>
              innerSum + (payroll[key] ? parseFloat(payroll[key]) : 0),
            0
          ),
        0
      )
      .toFixed(2);
  };
  const handleExportDetailedReport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Payroll Data');

    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([`Federal Tax ID: ${federalTaxId || ''}`]); // Row for Legal Business Name
    worksheet.addRow([
      `Employee Name: ${rows[0]?.payrolls[0]?.employeeName || ''}`
    ]); // Row for DBA
    worksheet.addRow([
      `Report Period:   ${
        selectedStartMonth ? moment(selectedStartMonth).format('MMM-YY') : '-'
      } to ${
        selectedEndMonth ? moment(selectedEndMonth).format('MMM-YY') : '-'
      }`
    ]); // Add an empty row
    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([
      'S.No',
      'State',
      'Business Name',
      'Month/Year',
      'Gross Wage',
      'Federal WTH',
      'Federal WTH'
    ]); // Row for Sale Name

    const federalId = worksheet.getCell('A2');
    const employeeName = worksheet.getCell('A3');
    const reportPeriod = worksheet.getCell('A4');

    federalId.font = { bold: true, color: { argb: 'ff1c4e80' } };
    employeeName.font = { bold: true, color: { argb: 'ff1c4e80' } };
    reportPeriod.font = { bold: true, color: { argb: 'ff1c4e80' } };

    worksheet.getRow(7).eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: 'ff1c4e80' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffc6eob4' }
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });

    worksheet.mergeCells('A2:E2'); // merging for Legal Business Name
    worksheet.mergeCells('A3:E3'); // merging for DBA
    worksheet.mergeCells('A4:E4'); // merging for Report Period

    worksheet.columns = [
      { width: 10 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 }
    ];

    // Add rows
    rows.forEach((item, itemIndex) => {
      item.payrolls.forEach((payroll, payrollIndex) => {
        worksheet.addRow([
          payrollIndex === 0 && itemIndex + 1,
          item.state,
          item.legalBusinessName,
          moment(new Date(`${payroll.month}-01-${payroll.year}`)).format(
            'MMM-YYYY'
          ),
          payroll.grossWages && +(+payroll.grossWages).toFixed(2),
          payroll.federalWH && +(+payroll.federalWH).toFixed(2),
          payroll.stateWH && +(+payroll.stateWH).toFixed(2)
        ]);
      });

      // Add a total row for each legal business name
      worksheet.addRow([
        'Total',
        '',
        '',
        '',
        +item.payrolls
          .reduce(
            (sum, payroll) =>
              sum + (payroll.grossWages ? parseFloat(payroll.grossWages) : 0),
            0
          )
          .toFixed(2),
        +item.payrolls
          .reduce(
            (sum, payroll) =>
              sum + (payroll.federalWH ? parseFloat(payroll.federalWH) : 0),
            0
          )
          .toFixed(2),
        +item.payrolls
          .reduce(
            (sum, payroll) =>
              sum + (payroll.stateWH ? parseFloat(payroll.stateWH) : 0),
            0
          )
          .toFixed(2)
      ]);

      // Add a blank row for spacing
      worksheet.addRow({
        index: '',
        state: '',
        legalBusinessName: '',
        date: '',
        grossWages: '',
        federalWH: '',
        stateWH: ''
      });
    });

    // Add a total of totals row
    worksheet.addRow([
      'Total of Totals',
      '',
      '',
      '',
      +rows
        .reduce(
          (sum, item) =>
            sum +
            item.payrolls.reduce(
              (innerSum, payroll) =>
                innerSum +
                (payroll.grossWages ? parseFloat(payroll.grossWages) : 0),
              0
            ),
          0
        )
        .toFixed(2),
      +rows
        .reduce(
          (sum, item) =>
            sum +
            item.payrolls.reduce(
              (innerSum, payroll) =>
                innerSum +
                (payroll.federalWH ? parseFloat(payroll.federalWH) : 0),
              0
            ),
          0
        )
        .toFixed(2),
      +rows
        .reduce(
          (sum, item) =>
            sum +
            item.payrolls.reduce(
              (innerSum, payroll) =>
                innerSum + (payroll.stateWH ? parseFloat(payroll.stateWH) : 0),
              0
            ),
          0
        )
        .toFixed(2)
    ]);

    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    saveAs(blob, 'payroll_data.xlsx');
  };

  const handleExportSummaryReport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([`Federal Tax ID: ${federalTaxId || ''}`]); // Row for Legal Business Name
    worksheet.addRow([
      `Employee Name: ${rows[0]?.payrolls[0]?.employeeName || ''}`
    ]); // Row for DBA
    worksheet.addRow([
      `Report Period:   ${
        selectedStartMonth ? moment(selectedStartMonth).format('MMM-YY') : '-'
      } to ${
        selectedEndMonth ? moment(selectedEndMonth).format('MMM-YY') : '-'
      }`
    ]); // Add an empty row
    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([]); // Add an empty row
    worksheet.addRow([
      'S.No',
      'State',
      'Business Name',
      'Gross Wage',
      'Federal WTH',
      'Federal WTH'
    ]); // Row for Sale Name

    const federalId = worksheet.getCell('A2');
    const employeeName = worksheet.getCell('A3');
    const reportPeriod = worksheet.getCell('A4');

    federalId.font = { bold: true, color: { argb: 'ff1c4e80' } };
    employeeName.font = { bold: true, color: { argb: 'ff1c4e80' } };
    reportPeriod.font = { bold: true, color: { argb: 'ff1c4e80' } };

    worksheet.getRow(7).eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: 'ff1c4e80' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffc6eob4' }
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });

    worksheet.mergeCells('A2:E2'); // merging for Legal Business Name
    worksheet.mergeCells('A3:E3'); // merging for DBA
    worksheet.mergeCells('A4:E4'); // merging for Report Period

    worksheet.columns = [
      { width: 10 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 }
    ];

    rows.forEach((item, itemIndex) => {
      const totalGrossWages = item.payrolls
        .reduce(
          (sum, payroll) =>
            sum + (payroll.grossWages ? parseFloat(payroll.grossWages) : 0),
          0
        )
        .toFixed(2);
      const totalFederalWH = item.payrolls
        .reduce(
          (sum, payroll) =>
            sum + (payroll.federalWH ? parseFloat(payroll.federalWH) : 0),
          0
        )
        .toFixed(2);
      const totalStateWH = item.payrolls
        .reduce(
          (sum, payroll) =>
            sum + (payroll.stateWH ? parseFloat(payroll.stateWH) : 0),
          0
        )
        .toFixed(2);

      worksheet.addRow([
        +itemIndex + 1,
        item.state,
        item.legalBusinessName,
        +totalGrossWages,
        +totalFederalWH,
        +totalStateWH
      ]);
    });

    // Add Total of Totals Row
    const totalOfTotals = {
      index: 'Total',
      grossWages: rows
        .reduce(
          (sum, item) =>
            sum +
            item.payrolls.reduce(
              (innerSum, payroll) =>
                innerSum +
                (payroll.grossWages ? parseFloat(payroll.grossWages) : 0),
              0
            ),
          0
        )
        .toFixed(2),
      federalWH: rows
        .reduce(
          (sum, item) =>
            sum +
            item.payrolls.reduce(
              (innerSum, payroll) =>
                innerSum +
                (payroll.federalWH ? parseFloat(payroll.federalWH) : 0),
              0
            ),
          0
        )
        .toFixed(2),
      stateWH: rows
        .reduce(
          (sum, item) =>
            sum +
            item.payrolls.reduce(
              (innerSum, payroll) =>
                innerSum + (payroll.stateWH ? parseFloat(payroll.stateWH) : 0),
              0
            ),
          0
        )
        .toFixed(2)
    };

    worksheet.addRow(totalOfTotals);

    // Apply bold styling for headers and total row
    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(worksheet.lastRow.number).font = { bold: true };

    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    saveAs(blob, 'export.xlsx');
  };

  const handleSearch = () => {
    if (!federalTaxId) {
      NotificationManager.error('Please enter Federal Tax ID');
      return;
    }

    fetchEmployeeReport();
  };

  const handleClear = () => {
    setFederalTaxId('');
    setEmpName('');
    setSelectedStartMonth(null);
    setSelectedEndMonth(null);
    setRows([]);
  };

  const fetchEmployeeReport = async () => {
    try {
      setLoading(true);
      const res = await getEmployeeReport({
        federalTaxId: federalTaxId,
        startDate: selectedStartMonth,
        endDate: selectedEndMonth
      });
      if (res.meta.status !== 200) {
        NotificationManager.error(res.meta.message);
        return;
      }
      setEmployeeReports(res.data);
      setRows(formatPayrollData(res.data));
      setEmpName(res?.data[0]?.employeeName || '');

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching clients:', error);
    }
  };

  const handleFederalTaxIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rawValue = event.target.value;
    const numericValue = rawValue.replace(/\D/g, '');

    let formattedValue = '';
    if (numericValue.length > 0) {
      formattedValue = numericValue.slice(0, 3);
      if (numericValue.length > 3) {
        formattedValue += '-' + numericValue.slice(3, 5);
      }
      if (numericValue.length > 5) {
        formattedValue += '-' + numericValue.slice(5, 9);
      }
    }
    setFederalTaxId(formattedValue);
  };

  // useEffect(() => {
  //   fetchEmployeeReport();
  // }, []);

  return (
    <>
      <Box my={2} mx={5}>
        {loading && (
          <div className="loader-wrapper" style={{ textAlign: 'center' }}>
            <BarLoader color={'#1976D2'} loading={loading} width={'94vw'} />
          </div>
        )}
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#1976D2',
            fontSize: '1.5em',
            my: 2
          }}
        >
          Employee Report
        </Typography>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={12} md={2}>
            <InputLabel htmlFor="client">Federal Tax Id</InputLabel>
            <TextField
              placeholder={'123-45-6789'}
              variant="outlined"
              fullWidth
              size="small"
              value={federalTaxId}
              onChange={handleFederalTaxIdChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputLabel htmlFor="client">Employee Name</InputLabel>
            <TextField
              placeholder={''}
              variant="outlined"
              fullWidth
              size="small"
              style={{ fontWeight: 'bold', pointerEvents: 'none' }}
              value={empName}
              // disabled={true}
            />
          </Grid>
          <Grid item xs={6} md={1.5} mt={0}>
            <InputLabel htmlFor="startMonth">Start Month</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ width: '100%' }}>
                <DatePicker
                  views={['year', 'month']}
                  openTo="year"
                  value={selectedStartMonth}
                  inputFormat="MMM yyyy"
                  onChange={setSelectedStartMonth}
                  renderInput={(props) => (
                    <TextField {...props} style={{ width: '100%' }} />
                  )}
                  InputProps={{
                    size: 'small',
                    name: 'startMonth'
                  }}
                />
              </Box>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} md={1.5} mt={0}>
            <InputLabel htmlFor="endMonth">End Month</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ width: '100%' }}>
                <DatePicker
                  views={['year', 'month']}
                  openTo="year"
                  value={selectedEndMonth}
                  inputFormat="MMM yyyy"
                  onChange={setSelectedEndMonth}
                  renderInput={(props) => (
                    <TextField {...props} style={{ width: '100%' }} />
                  )}
                  InputProps={{
                    size: 'small',
                    name: 'endMonth'
                  }}
                />
              </Box>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3.5} sm={3} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="warning"
              onClick={handleClear}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Clear
            </Button>
          </Grid>
          <Grid item xs={4} sm={2} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Search
              <SearchIcon />
            </Button>
          </Grid>
          <Grid item xs={0.1} sm={0} md={2}></Grid>
          <Grid item xs={3.8} sm={2} md={1} sx={{ mt: { md: 3 } }}>
            <Button
              variant="contained"
              color="success"
              onClick={
                reportType === 'Summary'
                  ? handleExportSummaryReport
                  : handleExportDetailedReport
              }
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Export
              <FileDownloadIcon />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        mx={5}
        mt={1}
        style={{ width: '96vw', display: 'flex', justifyContent: 'start' }}
      >
        <Grid container style={{display: 'flex', justifyContent: 'start'}}  mt={0}>
          <Grid item xs={4} md={3}>
          <ToggleButtonGroup
          size="small"
          value={reportType}
          exclusive
          onChange={handleReportTypeChange}
          style={{ marginRight: '2vw' }}
        >
          <ToggleButton
            value="Summary"
            style={{
              backgroundColor: reportType === 'Summary' ? '#1976d2' : 'white',
              color: reportType === 'Summary' ? 'white' : 'black',
              border: '1px solid #ccc'
            }}
          >
            Summary
          </ToggleButton>
          <ToggleButton
            value="Detailed"
            style={{
              backgroundColor: reportType === 'Detailed' ? '#1976d2' : 'white',
              color: reportType === 'Detailed' ? 'white' : 'black',
              border: '1px solid #ccc'
            }}
          >
            Detailed
          </ToggleButton>
        </ToggleButtonGroup>
          </Grid>
        </Grid>
       
      </Box>
      {reportType === 'Detailed' && (
        <Box mx={5} mt={1}>
          <Paper sx={{ width: '96vw', overflow: 'hidden' }} elevation={3}>
            <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: '800',
                          color: '#1976D2',
                          border: '1px solid #E3E1D9',
                          borderCollapse: 'collapse',
                          padding: 5
                        }}
                      >
                        {/* <TableSortLabel>{column.label}</TableSortLabel> */}
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((item, itemIndex) => (
                    <React.Fragment key={itemIndex}>
                      {/* Render each payroll entry */}
                      {item.payrolls.map((payroll, payrollIndex) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={payrollIndex}
                        >
                          <TableCell sx={styles.cell}>
                            {payrollIndex === 0 ? itemIndex + 1 : ''}
                          </TableCell>
                          <TableCell sx={styles.cell}>
                            {item.state && item.state}
                          </TableCell>
                          <TableCell sx={styles.cell}>
                            {item.legalBusinessName}
                          </TableCell>
                          <TableCell sx={styles.cell}>
                            {moment(
                              new Date(`${payroll.month}-01-${payroll.year}`)
                            ).format('MMM-YYYY')}
                          </TableCell>
                          <TableCell sx={styles.cell}>
                            {payroll.grossWages
                              ? (+payroll.grossWages).toFixed(2)
                              : ''}
                          </TableCell>
                          <TableCell sx={styles.cell}>
                            {payroll.federalWH
                              ? (+payroll.federalWH).toFixed(2)
                              : ''}
                          </TableCell>
                          <TableCell sx={styles.cell}>
                            {payroll.stateWH
                              ? (+payroll.stateWH).toFixed(2)
                              : ''}
                          </TableCell>
                        </TableRow>
                      ))}

                      {/* Total Row for Each Legal Business Name */}
                      <TableRow>
                        <TableCell
                          sx={styles.cellTotal}
                          colSpan={4}
                          style={{ textAlign: 'center' }}
                        >
                          Total
                        </TableCell>
                        <TableCell sx={styles.cellTotal}>
                          {item.payrolls
                            .reduce(
                              (sum, payroll) =>
                                sum +
                                (payroll.grossWages
                                  ? parseFloat(payroll.grossWages)
                                  : 0),
                              0
                            )
                            .toFixed(2)}
                        </TableCell>
                        <TableCell sx={styles.cellTotal}>
                          {item.payrolls
                            .reduce(
                              (sum, payroll) =>
                                sum +
                                (payroll.federalWH
                                  ? parseFloat(payroll.federalWH)
                                  : 0),
                              0
                            )
                            .toFixed(2)}
                        </TableCell>
                        <TableCell sx={styles.cellTotal}>
                          {item.payrolls
                            .reduce(
                              (sum, payroll) =>
                                sum +
                                (payroll.stateWH
                                  ? parseFloat(payroll.stateWH)
                                  : 0),
                              0
                            )
                            .toFixed(2)}
                        </TableCell>
                      </TableRow>

                      {/* Add a blank row for spacing */}
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          style={{ height: '16px' }}
                        ></TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}

                  {/* Total of Totals Row */}
                  {rows.length > 0 && (
                    <TableRow>
                      <TableCell
                        sx={styles.cellTotal}
                        colSpan={4}
                        style={{ textAlign: 'center' }}
                      >
                        Total of Totals
                      </TableCell>
                      <TableCell sx={styles.cellTotal}>
                        {rows
                          .reduce(
                            (sum, item) =>
                              sum +
                              item.payrolls.reduce(
                                (innerSum, payroll) =>
                                  innerSum +
                                  (payroll.grossWages
                                    ? parseFloat(payroll.grossWages)
                                    : 0),
                                0
                              ),
                            0
                          )
                          .toFixed(2)}
                      </TableCell>
                      <TableCell sx={styles.cellTotal}>
                        {rows
                          .reduce(
                            (sum, item) =>
                              sum +
                              item.payrolls.reduce(
                                (innerSum, payroll) =>
                                  innerSum +
                                  (payroll.federalWH
                                    ? parseFloat(payroll.federalWH)
                                    : 0),
                                0
                              ),
                            0
                          )
                          .toFixed(2)}
                      </TableCell>
                      <TableCell sx={styles.cellTotal}>
                        {rows
                          .reduce(
                            (sum, item) =>
                              sum +
                              item.payrolls.reduce(
                                (innerSum, payroll) =>
                                  innerSum +
                                  (payroll.stateWH
                                    ? parseFloat(payroll.stateWH)
                                    : 0),
                                0
                              ),
                            0
                          )
                          .toFixed(2)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
            rowsPerPageOptions={[50, 100, 200]}
            count={payrolls.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          </Paper>
        </Box>
      )}
      {reportType === 'Summary' && (
        <Box mx={5} mt={1}>
          <Paper sx={{ width: '96vw', overflow: 'hidden' }} elevation={3}>
            <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {summaryColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: '800',
                          color: '#1976D2',
                          border: '1px solid #E3E1D9',
                          borderCollapse: 'collapse',
                          padding: 5
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((item, itemIndex) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={itemIndex}
                    >
                      <TableCell sx={styles.cell}>{itemIndex + 1}</TableCell>
                      <TableCell sx={styles.cell}>{item.state}</TableCell>
                      <TableCell sx={styles.cell}>
                        {item.legalBusinessName}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {item.payrolls
                          .reduce(
                            (sum, payroll) =>
                              sum +
                              (payroll.grossWages
                                ? parseFloat(payroll.grossWages)
                                : 0),
                            0
                          )
                          .toFixed(2)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {item.payrolls
                          .reduce(
                            (sum, payroll) =>
                              sum +
                              (payroll.federalWH
                                ? parseFloat(payroll.federalWH)
                                : 0),
                            0
                          )
                          .toFixed(2)}
                      </TableCell>
                      <TableCell sx={styles.cell}>
                        {item.payrolls
                          .reduce(
                            (sum, payroll) =>
                              sum +
                              (payroll.stateWH
                                ? parseFloat(payroll.stateWH)
                                : 0),
                            0
                          )
                          .toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* Total of Totals Row */}
                  {rows.length > 0 && (
                    <TableRow>
                      <TableCell
                        sx={styles.cellTotal}
                        colSpan={3}
                        style={{ textAlign: 'center' }}
                      >
                        Total
                      </TableCell>
                      <TableCell sx={styles.cellTotal}>
                        {calculateTotal('grossWages')}
                      </TableCell>
                      <TableCell sx={styles.cellTotal}>
                        {calculateTotal('federalWH')}
                      </TableCell>
                      <TableCell sx={styles.cellTotal}>
                        {calculateTotal('stateWH')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default EmployeeReport;

const formatPayrollData = (data: any) => {
  // Create a map to group payrolls by client
  const clientMap = {};

  // Iterate through the data array
  data.forEach((item) => {
    const clientId = item.client.id;
    // Initialize the client entry if it doesn't exist
    if (!clientMap[clientId]) {
      clientMap[clientId] = {
        legalBusinessName: item.client.legalBusinessName,
        state: item.client.state,
        payrolls: []
      };
    }

    // Add the current payroll record to the client's payrolls
    clientMap[clientId].payrolls.push({
      id: item.id,
      clientId: item.clientId,
      month: item.month,
      year: item.year,
      employeeName: item.employeeName,
      federalTaxId: item.federalTaxId,
      grossWages: item.grossWages,
      socSec: item.socSec,
      medicare: item.medicare,
      federalWH: item.federalWH,
      stateWH: item.stateWH,
      localWH: item.localWH,
      tips: item.tips,
      benefitDeduction: item.benefitDeduction,
      netPay: item.netPay,
      futaTaxable: item.futaTaxable,
      futaTax: item.futaTax,
      futaCredit: item.futaCredit,
      sutaTaxable: item.sutaTaxable,
      sutaTax: item.sutaTax,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt
    });
  });

  // Convert the clientMap object into an array
  return [...Object.values(clientMap)];
};
