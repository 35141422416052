import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const ViewField = ({ label, value }) => {
  return (
    <Grid item xs={12} sm={4} >
      <Box p={1}>
        <Typography variant="body2" color={'#1976D2'}>
          {label}
          <br />
          <Typography
            component="span"
            style={{
              color:
                value === 'Active'
                  ? 'green'
                  : value === 'Inactive'
                  ? 'red'
                  : '#323a45',
              fontSize: '1.1rem'
            }}
          >
            {value}
          </Typography>
        </Typography>
      </Box>
    </Grid>
  );
};

export default ViewField;
