// fetchWrapper.ts
import { API_BASE_URL } from './constants/common';
import { NotificationManager } from 'react-notifications';
// Define a type for the navigate function
type NavigateFunction = (path: string) => void;

// Set the navigate callback in the fetch wrapper
let navigateCallback: NavigateFunction | undefined;

export const setNavigateCallback = (callback: NavigateFunction) => {
  navigateCallback = callback;
};

const handleUnauthorized = () => {
  const INACTIVITY_THRESHOLD = 30 * 60 * 1000;

  let lastActivityTime: any = localStorage.getItem('lastActivityTime');
  lastActivityTime = new Date(+lastActivityTime).getTime();
  let currentTime: any = Date.now().toString();
  currentTime = new Date(+currentTime).getTime();

  if (lastActivityTime) {
    const lastActivityTimestamp: number = +lastActivityTime;
    const timeDiff = currentTime - lastActivityTimestamp;

    if (timeDiff > INACTIVITY_THRESHOLD) {
      // Assuming navigateCallback is a function that handles navigation
      if (navigateCallback) {
        navigateCallback('/login');
        NotificationManager.error('Session Expired', 'Error', 3000);
      } else {
        console.error('Navigate callback is not set');
      }
    }
  }
};

const fetchWrapper = async (
  url: string,
  options?: RequestInit
): Promise<Response> => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const defaultOptions: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    }
  };

  const combinedOptions = { ...defaultOptions, ...options };

  try {
    handleUnauthorized();
    localStorage.setItem('lastActivityTime', Date.now().toString());

    const response: any = await fetch(`${API_BASE_URL}${url}`, combinedOptions);

    if (response.status === 401) {
      if (navigateCallback) {
        navigateCallback('/login');
        return NotificationManager.error('Un-Authorized', 'Error', 3000);
      } else {
        console.error('Navigate callback is not set');
      }
    }
    //   console.log(response);
    //   console.log(response.status);
    return response;
  } catch (error) {
    console.error('Error in fetchWrapper:', error);
    throw new Error('Failed to fetch data');
  }
};

export default fetchWrapper;
