import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
// import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import 'sweetalert2/dist/sweetalert2.min.css';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

NotificationContainer.defaultProps = {
  position: 'top-right' // or 'bottom-right' or 'top-left' or 'bottom-left'
};

ReactDOM.render(
  <HelmetProvider>
    {/* <SidebarProvider> */}
    <BrowserRouter>
      <App />
      <NotificationContainer />
    </BrowserRouter>
    {/* </SidebarProvider> */}
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
