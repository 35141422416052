import {
    Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from '@mui/material';
import React from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

const TodoCard = ({status = 'Completed', taskTitle = 'Task Title', priority = 'Medium', dueDate = '2024-10-30'}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
   <Card
  sx={{ border: '1px solid #B0BEC5', dropShadow: 5, shadow: 2, minWidth: 275, width: 300, height: 225, backgroundColor: '#D1E9F6', borderRadius: 2, boxShadow: 3, position: 'relative' }}
>
  <Typography
    variant="body2"
    sx={{
      position: 'absolute',
      top: 16,
      left: 16,
      backgroundColor: status === 'Completed' ? '#4caf50' : status === 'In Progress' ? '#FFB22C' : '#f44336',
      color: '#fff',
      padding: '4px 8px',
      borderRadius: '8px',
      fontWeight: 'bold',
    }}
  >
    {status}
  </Typography>
  
  <CardContent>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'flex-end', height:'30px' }}>
    <EditIcon sx={{ mr: 1, cursor: 'pointer' }} fontSize='small'/>
    <LaunchIcon fontSize='small' sx={{ cursor: 'pointer' }} />
    </Box>
    <Typography variant="h6" component="div" sx={{ fontWeight: '600', color: 'text.secondary', lineHeight: 1.2, mt: 2, mb: 2 }}>
      One liner title for the task will be written here.
    </Typography>
    <Typography sx={{ color: 'text.secondary', mt: 1 }}>
      Due Date: <span style={{ fontWeight: '500' }}>{moment(dueDate).format('DD-MMM-YYYY')}</span>
    </Typography>
    <Typography sx={{ color: 'text.secondary' }}>
      Accountant: <span style={{ fontWeight: '500' }}>{'John Doe'}</span>
    </Typography>
    <Typography sx={{ color: 'text.secondary' }}>
      Client Name: <span style={{ fontWeight: '500' }}>{'John Doe'}</span>
    </Typography>
    {/* <Box sx={{mb: 1, mt: 1, display: 'flex', alignItems: 'center', justifyContent:'center', height:'60px', border: '1px solid #B0BEC5', borderRadius: '8px', px: 1, backgroundColor: '#F7F9F2'}}> */}
    {/* </Box> */}

    {/* <Typography sx={{ color: 'text.secondary', mb: 1 }}>
      Priority: <span style={{ fontWeight: '500' }}>High</span>
    </Typography> */}
    {/* <Typography sx={{ mb: 2 }}>
      Short description of the task goes here. It provides a brief overview.2 of the task's purpose.
    </Typography> */}
    {/* <Button size="small" onClick={handleExpand}>
      {isExpanded ? 'Show Less' : 'Read More'}
    </Button> */}
    
  </CardContent>
  
  {/* {isExpanded && (
    <CardContent sx={{ borderTop: '1px solid #B0BEC5', pt: 2 }}>
      <Typography variant="body2">
        Additional details about the task can be entered here. Include more information, context, or instructions as needed.
      </Typography>
      <Button size="small" onClick={handleEdit}>
        Edit
      </Button>
    </CardContent>
  )} */}
</Card>

    </div>
  );
};

export default TodoCard;
