import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Grid,
  InputLabel
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { empRegister, registerEmployee } from 'src/services/authService';
import { editEmployee, getAllEmployees } from 'src/services/employeeService';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { updateEmployee } from 'src/services/employeeService';
import {
  employeeRegistrationPayload,
  updateEmployeePayload
} from 'src/interface/employee';
import { id } from 'date-fns/locale';
import { css } from '@emotion/react';
import { BarLoader } from 'react-spinners';

const EmployeesForm = ({ open, onClose, employee, action }) => {
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  });
  const requiredFields = ['firstName', 'email', 'password'];

  useEffect(() => {
    // If client object is provided, populate the form fields
    if (employee) {
      employee.password = '';
      setEmployeeData(employee);
    }
  }, [employee]);

  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (field, value) => {
    if ((field === 'firstName' || field === 'lastName') && /\d/.test(value)) {
      return;
    }

    setEmployeeData((prevData) => ({
      ...prevData,
      [field]: value
    }));
  };

  const employeeRegisterFunction = async (
    empData: employeeRegistrationPayload
  ) => {
    const response: registerEmployee = await empRegister({ ...empData });
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(response);
      }, 1000);
    });
  };

  const editEmployeeFunction = async (
    id: number,
    data: updateEmployeePayload
  ) => {
    setLoading(true);
    console.log('working');
    console.log(id, data)

    const response: updateEmployee = await editEmployee(id, { ...data });

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(response);
      });
      setLoading(false);

    });
  };

  const setEmployeeDataToEmpty = ()=>{
  employeeData.email = '';
  employeeData.firstName = '';
  employeeData.lastName = '';
  employeeData.password = '';
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('submit');
    setLoading(true)
  

    // Check for empty fields
    const errors = {};
    requiredFields.forEach((field) => {
      if (!employeeData[field]) {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
      }
    });

    setValidationErrors(errors);

    // If no empty fields, proceed with submission
    if (Object.keys(errors).length === 0) {
      try {
        if (action === 'edit') {
          console.log(action);
      
          const editedEmployee: any = await editEmployeeFunction(
            employee.userId,
            employeeData
          );
          setLoading(true);
          if (editedEmployee) {
          setLoading(false);
          }
          console.log('edit okay');
          if (editedEmployee.meta.status === 200) {
            // Employee created successfully
            Swal.fire({
              text: 'Employee Add Successfully',
              icon: 'success',
              confirmButtonText: 'Close!'
            });
          } 

          // Clear form fields after successful submission
          setEmployeeData({
            firstName: '',
            lastName: '',
            email: '',
            password: ''
          });

          // Close the form or perform other actions as needed
          onClose();
        } else {
          // Error while creating employee
            const employee: any = await employeeRegisterFunction(employeeData);
            setLoading(false);
            onClose(true)
            getAllEmployees()
            setEmployeeDataToEmpty()
          // Swal.fire({
          //   text: `Error! ${employee.meta.message}`,
          //   icon: 'error',
          //   confirmButtonText: 'Close!'
          // });
        }
      } catch (error) {
        // Handle any errors that occur during the submission
        console.error('Error during submission:', error);
        setLoading(false);

        // You might want to show a different alert or handle the error in another way
        Swal.fire({
          text: `Error! ${error.message}`,
          icon: 'error',
          confirmButtonText: 'Close!'
        });
        onClose();
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(8px)', // Adjust blur intensity as needed
        WebkitBackdropFilter: 'blur(8px)'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '10px',
          maxHeight: '80vh',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar for Chrome/Safari
          },
          msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
          scrollbarWidth: 'none',
        }}
      >
        <CloseIcon
          sx={{
            position: 'fixed',
            top: 10,
            right: 10,
            cursor: 'pointer',
            zIndex: 2
          }}
          onClick={onClose}
        />

        {action === 'edit' ? (
          <Typography
            variant="h3"
            component="div"
            sx={{ marginBottom: 2, color: '#1976D2' }}
          >
            Edit Employee
          </Typography>
        ) : (
          <Typography
            variant="h3"
            component="div"
            sx={{ marginBottom: 2, color: '#1976D2' }}
          >
            Add Employee
          </Typography>
        )}

        {loading && (
          <div className="loader-wrapper" style={{ textAlign: 'center' }}>
            <BarLoader color={'#1976D2'} loading={loading} width={'inherit'}/>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="firstName">First Name</InputLabel>
              <TextField
                id="firstName"
                placeholder="Aashir"
                variant="outlined"
                fullWidth
                size="small"
                value={employeeData.firstName}
                onChange={(e) => handleInputChange('firstName', e.target.value)}
                error={!!validationErrors['firstName']}
                helperText={validationErrors['firstName']}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="firstName">Last Name</InputLabel>
              <TextField
                id="lastName"
                placeholder="Aashir"
                variant="outlined"
                fullWidth
                size="small"
                value={employeeData.lastName}
                onChange={(e) => handleInputChange('lastName', e.target.value)}
                error={!!validationErrors['lastName']}
                helperText={validationErrors['lastName']}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <TextField
                id="email"
                placeholder="Aashir"
                variant="outlined"
                fullWidth
                size="small"
                value={employeeData.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                error={!!validationErrors['email']}
                helperText={validationErrors['email']}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <TextField
                id="password"
                placeholder="password"
                variant="outlined"
                type="password"
                fullWidth
                size="small"
                value={employeeData.password}
                onChange={(e) => handleInputChange('password', e.target.value)}
                error={!!validationErrors['password']}
                helperText={validationErrors['password']}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 2
            }}
          >
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default EmployeesForm;
