import { InputAdornment, InputLabel, TextField } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';

export const formatNumberWithCommas = (value) => {
  // Check if value exists and is a number
  if (value && !isNaN(value)) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
};



const SalesDoubleInput = ({
  value1,
  value2,
  label,
  handleInputChange,
  name = '',
  validationField
}) => {
  const showPercentage = name !== 'gasolineGallon' && name !== 'gasolineAmount';
  const formattedValue1 = formatNumberWithCommas(value1);
  const formattedValue2 = formatNumberWithCommas(
    value2 && Number (value2).toFixed(3)
  );

  return (
    <>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{ xs: { mt: 0 } }}>
            <InputLabel sx={{ mt: 1 }}>{label}</InputLabel>
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label=""
              value={value1!==null? value1: ''}
              variant="outlined"
              size="small"
              onChange={(e) => handleInputChange(name, e.target.value)}
              error={!!validationField}
              helperText={validationField}
              InputProps={
                showPercentage
                  ? {
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }
                  : {}
              }
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label=""
              variant="outlined"
              size="small"
              InputProps={
                showPercentage
                  ? {
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }
                  : { readOnly: true }
              }
              id=""
              placeholder=""
              fullWidth
              value={formattedValue2 || ''}
              sx={{
                backgroundColor: '#f5f5f5'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SalesDoubleInput;
// export { formatNumberWithCommas };