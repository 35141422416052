import React from 'react';
import { Typography, Grid } from '@mui/material';

const FormHeading = ({ text, color = 'secondary', center = false }) => {
  return (
    <Grid item xs={12} md={12}>
      <Typography
        variant="h5"
        component="div"
        sx={{ marginTop: 2, color, textAlign: center ? 'center' : 'left' }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export default FormHeading;
