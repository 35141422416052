import fetchWrapper from "src/fetchWrapper";
import { IAddEmployessPayrollPayload } from "src/interface/payroll";

export const addEmployessPayroll = async (data: IAddEmployessPayrollPayload): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  console.log(data);

  const response = await fetchWrapper(`/payroll/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();
};

export const getEmployeePayrollsByFederaltaxIdAndMonthAndYear = async (data: {
  federalTaxId: string,
  month: number,
  year: number,
}) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const response = await fetchWrapper(`/payroll/${data.federalTaxId}/${data.month}/${data.year}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
  })

  return response.json();
}

export const getClientWisePayrolls = async (startDate: string = '', endDate: string = '') => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const response = await fetchWrapper(`/payroll/employeePayrolls/?startDate=${startDate}&endDate=${endDate}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();

}
export const getClientWisePayrollsByParams = async (payload:{clientId: number, monthStart: string, monthEnd: string}) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const { clientId, monthStart, monthEnd } = {
    clientId: payload.clientId,
    monthStart: payload.monthStart,
    monthEnd: payload.monthEnd};
  const response = await fetchWrapper(`/payroll/PayrollsByParams/${clientId}/${monthStart}/${monthEnd}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();

}

export const previousMonthPayrollEmail = async (data: {
  federalTaxId: string,
  month: number,
  year: number,
}) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  console.log(data);

  const response = await fetchWrapper(`/payroll/sendPreviousMonthPayrollsEmail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();
};

export const deletePayroll = async (data: {
  clientId: number,
  month: number,
  year: number,
}) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/payroll/employeePayrolls/${data.clientId}/${data.month}/${data.year}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
  }
}

export const getEmployeeReport = async (data:{federalTaxId: string, startDate: string, endDate: string}) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/reports/employeeReport/?federalTaxId=${data.federalTaxId}&startDate=${data.startDate}&endDate=${data.endDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
    return response?.json().then((data) => data);
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
  }
}