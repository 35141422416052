import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';

interface OTPModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (otp: number) => void;
  onResend: () => any;
}

const OTPModal: React.FC<OTPModalProps> = ({
  open,
  onClose,
  onSubmit,
  onResend
}) => {
  const [otp, setOTP] = useState<string[]>(['', '', '', '', '', '']);
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(30);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (resendEnabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendEnabled(false);
      setTimer(30);
    }

    return () => clearInterval(interval);
  }, [resendEnabled, timer]);

  const otpInputs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (open) {
      otpInputs.current[0]?.focus();
      otpInputs.current[0]?.setSelectionRange(0, 0);
    }
  }, [open]);

  const handleOTPChange = (index: number, value: string) => {
    // Allow only digits and limit to 1 character
    const newDigit = value.replace(/\D/g, '').slice(0, 1);

    const newOTP = [...otp];
    newOTP[index] = newDigit;
    setOTP(newOTP);

    // Move focus to the previous input box on backspace
    if (value === '' && index > 0 && otpInputs.current[index - 1]) {
      otpInputs.current[index - 1]?.focus();
    }

    // Move focus to the next input box if a digit is entered
    if (newDigit !== '' && index < 5 && otpInputs.current[index + 1]) {
      otpInputs.current[index + 1]?.focus();
    }
  };

  const handleResendOTP = () => {
    setOTP(['', '', '', '', '', '']);
    onResend();
    setResendEnabled(true);
  };

  const handleSubmit = () => {
    // Here you can validate the OTP, for demonstration, we're just logging it
    const fullOTP = otp.join('');
    console.log('Submitted OTP:', fullOTP);
    onSubmit(+fullOTP);
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Enter' && index === 5) {
      handleSubmit();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>Enter OTP</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {otp.map((digit, index) => (
            <TextField
              key={index}
              size="small"
              inputRef={(input) => (otpInputs.current[index] = input)}
              autoFocus={index === 0}
              required
              label=""
              type="tel"
              variant="outlined"
              value={digit}
              onChange={(e) => handleOTPChange(index, e.target.value)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(e, index)}
              inputProps={{ maxLength: 1, pattern: '[0-9]*' }}
              style={{ width: '40px', margin: '0 5px' }}
            />
          ))}
        </div>
        {resendEnabled ? (
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            gutterBottom
          >
            Resend OTP in {timer} seconds
          </Typography>
        ) : (
          <Button
            variant="text"
            color="primary"
            onClick={handleResendOTP}
            fullWidth
          >
            Resend OTP
          </Button>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={otp.some((digit) => digit === '')}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OTPModal;
