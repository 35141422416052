import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialogue({
  open,
  close,
  confirmationMessage,
  onConfirm
}) {
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          borderRadius: '20px',
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)'
        }}
      >
        <DialogContent sx={{ padding: '24px' }}>
          <DialogContentText id="alert-dialog-description">
            <h3
              style={{
                textAlign: 'center',
                marginBottom: '16px',
                color: '#333'
              }}
            >
              {confirmationMessage}
            </h3>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ padding: '8px 24px 24px', justifyContent: 'center' }}
        >
          <Button
            onClick={close}
            variant="contained"
            sx={{
              marginRight: '8px',
              backgroundColor: '#f44336',
              color: '#fff'
            }}
          >
            No
          </Button>
          <Button
            onClick={onConfirm}
            autoFocus
            variant="contained"
            sx={{ backgroundColor: '#4caf50', color: '#fff' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
