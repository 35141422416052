import validator from 'validator';

export const validateSalesFormDate = (salesData) => {
  const errors: any = {};
  console.log('salesData in validate', salesData);

  errors.gasolineGallon =
    !validator.isEmpty(salesData.gasolineGallon) &&
    (!validator.isNumeric(salesData.gasolineGallon) ||
      !validator.isFloat(salesData.gasolineGallon)) &&
    'Must be a Numeric';
  errors.gasolineAmount =
    !validator.isEmpty(salesData.gasolineAmount) &&
    !validator.isNumeric(salesData.gasolineAmount) &&
    !validator.isFloat(salesData.gasolineAmount) &&
    'Must be a Numeric';
  errors.carWash =
    !validator.isEmpty(salesData.carWash) &&
    !validator.isNumeric(salesData.carWash) &&
    !validator.isFloat(salesData.carWash) &&
    'Must be a Numeric';
  errors.newsPaper =
    !validator.isEmpty(salesData.newsPaper) &&
    !validator.isNumeric(salesData.newsPaper) &&
    !validator.isFloat(salesData.newsPaper) &&
    'Must be a Numeric';
  errors.ebt =
    !validator.isEmpty(salesData.ebt) &&
    !validator.isNumeric(salesData.ebt) &&
    !validator.isFloat(salesData.ebt) &&
    'Must be a Numeric';
  errors.exemptFood =
    !validator.isEmpty(salesData.exemptFood) &&
    !validator.isNumeric(salesData.exemptFood) &&
    !validator.isFloat(salesData.exemptFood) &&
    'Must be a Numeric';
  errors.saleTaxHoliday =
    !validator.isEmpty(salesData.saleTaxHoliday) &&
    !validator.isNumeric(salesData.saleTaxHoliday) &&
    !validator.isFloat(salesData.saleTaxHoliday) &&
    'Must be a Numeric';
  errors.otherExempt =
    !validator.isEmpty(salesData.otherExempt) &&
    !validator.isNumeric(salesData.otherExempt) &&
    !validator.isFloat(salesData.otherExempt) &&
    'Must be a Numeric';
  errors.highTaxGrocery =
    !validator.isEmpty(salesData.highTaxGrocery) &&
    !validator.isNumeric(salesData.highTaxGrocery) &&
    !validator.isFloat(salesData.highTaxGrocery) &&
    'Must be a Numeric';
  errors.beer =
    !validator.isEmpty(salesData.beer) &&
    !validator.isNumeric(salesData.beer) &&
    !validator.isFloat(salesData.beer) &&
    'Must be a Numeric';
  errors.cigarettes =
    !validator.isEmpty(salesData.cigarettes) &&
    !validator.isNumeric(salesData.cigarettes) &&
    !validator.isFloat(salesData.cigarettes) &&
    'Must be a Numeric';
  errors.hotFoodDeli =
    !validator.isEmpty(salesData.hotFoodDeli) &&
    !validator.isNumeric(salesData.hotFoodDeli) &&
    !validator.isFloat(salesData.hotFoodDeli) &&
    'Must be a Numeric';
  errors.fountainDrink =
    !validator.isEmpty(salesData.fountainDrink) &&
    !validator.isNumeric(salesData.fountainDrink) &&
    !validator.isFloat(salesData.fountainDrink) &&
    'Must be a Numeric';
  errors.hotCoffee =
    !validator.isEmpty(salesData.hotCoffee) &&
    !validator.isNumeric(salesData.hotCoffee) &&
    !validator.isFloat(salesData.hotCoffee) &&
    'Must be a Numeric';
  errors.tobacco =
    !validator.isEmpty(salesData.tobacco) &&
    !validator.isNumeric(salesData.tobacco) &&
    !validator.isFloat(salesData.tobacco) &&
    'Must be a Numeric';
  errors.novelties =
    !validator.isEmpty(salesData.novelties) &&
    !validator.isNumeric(salesData.novelties) &&
    !validator.isFloat(salesData.novelties) &&
    'Must be a Numeric';
  errors.phoneCards =
    !validator.isEmpty(salesData.phoneCards) &&
    !validator.isNumeric(salesData.phoneCards) &&
    !validator.isFloat(salesData.phoneCards) &&
    'Must be a Numeric';
  errors.cbdDelta =
    !validator.isEmpty(salesData.cbdDelta) &&
    !validator.isNumeric(salesData.cbdDelta) &&
    !validator.isFloat(salesData.cbdDelta) &&
    'Must be a Numeric';
  errors.candies =
    !validator.isEmpty(salesData.candies) &&
    !validator.isNumeric(salesData.candies) &&
    !validator.isFloat(salesData.candies) &&
    'Must be a Numeric';
  errors.wine =
    !validator.isEmpty(salesData.wine) &&
    !validator.isNumeric(salesData.wine) &&
    !validator.isFloat(salesData.wine) &&
    'Must be a Numeric';
  errors.otherHigh =
    !validator.isEmpty(salesData.otherHigh) &&
    !validator.isNumeric(salesData.otherHigh) &&
    !validator.isFloat(salesData.otherHigh) &&
    'Must be a Numeric';
  errors.lowTaxGrocery =
    !validator.isEmpty(salesData.lowTaxGrocery) &&
    !validator.isNumeric(salesData.lowTaxGrocery) &&
    !validator.isFloat(salesData.lowTaxGrocery) &&
    'Must be a Numeric';
  errors.foodIngredients =
    !validator.isEmpty(salesData.foodIngredients) &&
    !validator.isNumeric(salesData.foodIngredients) &&
    !validator.isFloat(salesData.foodIngredients) &&
    'Must be a Numeric';
  errors.iceCream =
    !validator.isEmpty(salesData.iceCream) &&
    !validator.isNumeric(salesData.iceCream) &&
    !validator.isFloat(salesData.iceCream) &&
    'Must be a Numeric';
  errors.bottledWater =
    !validator.isEmpty(salesData.bottledWater) &&
    !validator.isNumeric(salesData.bottledWater) &&
    !validator.isFloat(salesData.bottledWater) &&
    'Must be a Numeric';
  errors.fruitsVegetables =
    !validator.isEmpty(salesData.fruitsVegetables) &&
    !validator.isNumeric(salesData.fruitsVegetables) &&
    !validator.isFloat(salesData.fruitsVegetables) &&
    'Must be a Numeric';
  errors.softDrinks =
    !validator.isEmpty(salesData.softDrinks) &&
    !validator.isNumeric(salesData.softDrinks) &&
    !validator.isFloat(salesData.softDrinks) &&
    'Must be a Numeric';
  errors.coffee =
    !validator.isEmpty(salesData.coffee) &&
    !validator.isNumeric(salesData.coffee) &&
    !validator.isFloat(salesData.coffee) &&
    'Must be a Numeric';
  errors.chips =
    !validator.isEmpty(salesData.chips) &&
    !validator.isNumeric(salesData.chips) &&
    !validator.isFloat(salesData.chips) &&
    'Must be a Numeric';
  errors.dairy =
    !validator.isEmpty(salesData.dairy) &&
    !validator.isNumeric(salesData.dairy) &&
    !validator.isFloat(salesData.dairy) &&
    'Must be a Numeric';
  errors.rawMeet =
    !validator.isEmpty(salesData.rawMeet) &&
    !validator.isNumeric(salesData.rawMeet) &&
    !validator.isFloat(salesData.rawMeet) &&
    'Must be a Numeric';
  errors.otherLow =
    !validator.isEmpty(salesData.otherLow) &&
    !validator.isNumeric(salesData.otherLow) &&
    !validator.isFloat(salesData.otherLow) &&
    'Must be a Numeric';

  for (const key in errors) {
    if (
      errors[key] === null ||
      errors[key] === undefined ||
      errors[key] === false
    ) {
      delete errors[key];
    }
  }
  return errors;
};
