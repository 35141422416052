import React from 'react'
import {Grid, InputLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material'

const RadioYesNo = ({label,value,handleInputChange,name}) => {
  return (
    <Grid item xs={12} md={12}>
    <InputLabel htmlFor={name}>
    {label}
    </InputLabel>
    <RadioGroup
      row
      value={value}
      onChange={(e) =>
        handleInputChange(
            name,
          e.target.value
        )
      }
    >
      <FormControlLabel
        value="true"
        control={<Radio />}
        label="Yes"
      />
      <FormControlLabel
        value="false"
        control={<Radio />}
        label="No"
      />
    </RadioGroup>
  </Grid>
  )
}

export default RadioYesNo