import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { BarLoader } from 'react-spinners';
import TodoCard from './todoCard';
import AddTodoTask from './addTodoTask';

const Todo = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Box sx={{ mt: 2, mx: '1vw' }}>
        {loading && (
          <div className="loader-wrapper" style={{ textAlign: 'center' }}>
            <BarLoader color={'#1976D2'} loading={loading} width={'96vw'} />
          </div>
        )}
      </Box>
      <Box
        sx={{ mt: 1 }}
        display={'flex'}
        justifyContent={'center'}
        width={'96vw'}
      >
        <Typography variant="h4" color={'#1976D2'}>
          TODO'S
        </Typography>
      </Box>

      <Box sx={{ mt: 3}}>
        <Grid container spacing={2} sx={{ px: '25px' }}>
          <Grid item xs={12} sm={3} sx={{ hover: { transform: 'scale(0.8)', transition: 'all 0.3s ease' } }}><AddTodoTask/></Grid>
          <Grid item xs={12} sm={3}><TodoCard status='In Progress'/></Grid>
          <Grid item xs={12} sm={3}><TodoCard status='Completed'/></Grid>
          <Grid item xs={12} sm={3}><TodoCard status='Overdue'/></Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Todo;
