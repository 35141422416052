import fetchWrapper from '../fetchWrapper';


export const getOwnershipReport = async (federalTaxId: string) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  try {
    const response = await fetchWrapper(`/reports/ownershipReport/?federalTaxId=${federalTaxId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const data = await response.json();
    console.log(data);
    
    return data; // Return the fetched data
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
    throw error; // Re-throw the error to be handled by the caller if necessary
  }
};

