import { Grid, Typography } from '@mui/material';
import React from 'react';

const ViewField = ({ label, value1, value2 }) => {
  
  const isTotalExemptions = label === 'Total Exemptions' || label=== 'Net Taxable Sales' || label === 'Total Sales Tax' || label === 'Net Sales Tax' ||label=== "Gross Sales"  ;

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          py: 1,
          color: isTotalExemptions ? 'black' : 'inherit',
        }}
      >
        <Grid item xs={6}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: isTotalExemptions ? 'bold' : 'normal',
              fontSize: isTotalExemptions ? '1.2em' : 'inherit',
            }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{
              color: isTotalExemptions ? 'black' : 'black',
              fontWeight: isTotalExemptions ? 'bold' : 'normal',
            }}
          >
            {value1}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{
              color: isTotalExemptions ? 'black' : 'black',
              fontWeight: isTotalExemptions ? 'bold' : 'normal',
            }}
          >
            {value2}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewField;
