import validator from 'validator';
export const validateBasicData = (clientData) => {
  const errors: any = {};

  // const isClientIdValid = validator.matches(
  //   clientData.clientId.trim(),
  //   /^[A-Za-z]-\d{5}$/
  // );
  // errors.clientId = validator.isEmpty(clientData.clientId)
  //   ? 'Client ID is required'
  //   : !isClientIdValid && "Enter a valid client ID in the format: 'A-00001'";
  // if (isClientIdValid) {
  //   clientData.clientId = clientData.clientId.replace(
  //     /^([A-Za-z])/,
  //     (_, alphabet) => alphabet.toUpperCase()
  //   );
  // }

  const isFederalTaxIdValid = validator.matches(
    clientData.federalTaxId.trim(),
    /^\d{2}-\d{7}$/
  );
  errors.federalTaxId = validator.isEmpty(clientData.federalTaxId)
    ? 'Federal Tax ID is required'
    : !isFederalTaxIdValid &&
      "Enter a valid federal tax ID in the format: '12-3456789'";
  //  if (isFederalTaxIdValid) {
  //   clientData.federalTaxId.replace(/^(\d{2})(\d{7})$/, '$1-$2');
  // }

  errors.businessStructure =
    validator.isEmpty(clientData.businessStructure) &&
    'Business Structure ID is required';
  errors.taxClassification =
    validator.isEmpty(clientData.taxClassification) &&
    'Tax Classification is required';
  errors.legalBusinessName =
    validator.isEmpty(clientData.legalBusinessName) && 'Industry is required';
  errors.industry =
    validator.isEmpty(clientData.industry) && 'Legal Business Name is required';
  errors.streetAddress1 =
    validator.isEmpty(clientData.streetAddress1) &&
    'Street Address 1 is required';
  errors.city = validator.isEmpty(clientData.city) && 'City is required';
  errors.county = validator.isEmpty(clientData.county) && 'County is required';
  errors.state = validator.isEmpty(clientData.state) && 'State is required';
  errors.zipCode =
    validator.isEmpty(clientData.zipCode) && 'Zip Code is required';
  errors.mailingStreetAddress1 =
    validator.isEmpty(clientData.mailingStreetAddress1) &&
    'Street Address 1 is required';
  errors.mailingCity =
    validator.isEmpty(clientData.mailingCity) && 'City is required';
  errors.mailingCounty =
    validator.isEmpty(clientData.mailingCounty) && 'County is required';
  errors.mailingState =
    validator.isEmpty(clientData.mailingState) && 'State is required';
  errors.mailingZipCode =
    validator.isEmpty(clientData.mailingZipCode) && 'Zip Code is required';
  errors.registeredAgent =
    validator.isEmpty(clientData.registeredAgent) &&
    'Registered Agent is required';
  //  errors.industries = validator.isEmpty(clientData.industries) && 'industry is required'
  for (const key in errors) {
    if (
      errors[key] === null ||
      errors[key] === undefined ||
      errors[key] === false
    ) {
      delete errors[key];
    }
  }
  return errors;
};

export const validateSalesTaxData = (clientSalesTaxData) => {
  const errors: any = {};
  // errors.salesTaxId = !validator.isNumeric(clientSalesTaxData.salesTaxId) && 'Sales Tax ID must be a number';
  errors.salesTaxId =
    validator.isEmpty(clientSalesTaxData.salesTaxId) &&
    'Sales Tax ID is required';
  errors.routingNumber = validator.isEmpty(clientSalesTaxData.routingNumber)
    ? 'Routing # is required'
    : !validator.isNumeric(clientSalesTaxData.routingNumber) &&
      'Routing # must be a number';
  errors.accountNumber = validator.isEmpty(clientSalesTaxData.accountNumber)
    ? 'Account # is required'
    : !validator.isNumeric(clientSalesTaxData.accountNumber) &&
      'Account # must be a number';

  errors.stateHighTax = validator.isEmpty(clientSalesTaxData.stateHighTax)
    ? 'State High Tax is required'
    : !validator.isNumeric(clientSalesTaxData.stateHighTax) &&
      'State High Tax must be a number';
  errors.stateLowTax = validator.isEmpty(clientSalesTaxData.stateLowTax)
    ? 'State Low Tax is required'
    : !validator.isNumeric(clientSalesTaxData.stateLowTax) &&
      'State Low Tax must be a number';
  errors.cityCountyTax = validator.isEmpty(clientSalesTaxData.cityCountyTax)
    ? 'City/County Taxes is required'
    : !validator.isNumeric(clientSalesTaxData.cityCountyTax) &&
      'City/County Tax must be a number';
  errors.countyTax = validator.isEmpty(clientSalesTaxData.countyTax)
    ? 'County Tax is required'
    : !validator.isNumeric(clientSalesTaxData.countyTax) &&
      'County Tax must be a number';
  errors.cityTax = validator.isEmpty(clientSalesTaxData.cityTax)
    ? 'City Tax Tax is required'
    : !validator.isNumeric(clientSalesTaxData.cityTax) &&
      'City Tax Tax must be a number';
  errors.liquorTax = validator.isEmpty(clientSalesTaxData.liquorTax)
    ? 'Liquor Tax is required'
    : !validator.isNumeric(clientSalesTaxData.liquorTax) &&
      'Liquor Tax must be a number';
  errors.beerTax = validator.isEmpty(clientSalesTaxData.beerTax)
    ? 'Beer Tax is required'
    : !validator.isNumeric(clientSalesTaxData.beerTax) &&
      'Beer Tax must be a number';
  errors.cbdTax = validator.isEmpty(clientSalesTaxData.cbdTax)
    ? 'CBD Tax is required'
    : !validator.isNumeric(clientSalesTaxData.cbdTax) &&
      'CBD Tax must be a number';
  errors.specialTax = validator.isEmpty(clientSalesTaxData.specialTax)
    ? 'Special Tax is required'
    : !validator.isNumeric(clientSalesTaxData.specialTax) &&
      'Special Tax must be a number';
  errors.fees911 = validator.isEmpty(clientSalesTaxData.fees911)
    ? '911 Fees is required'
    : !validator.isNumeric(clientSalesTaxData.fees911) &&
      '911 Fees must be a number';
  errors.discountRate = validator.isEmpty(clientSalesTaxData.discountRate)
    ? 'Discount Rate is required'
    : !validator.isNumeric(clientSalesTaxData.discountRate) &&
      'Discount Rate must be a number';

  for (const key in errors) {
    if (
      errors[key] === null ||
      errors[key] === undefined ||
      errors[key] === false
    ) {
      delete errors[key];
    }
  }
  return errors;
};

export const validateBusinessTaxData = (businessTaxData) => {
  const errors: any = {};

  errors.businessTaxId =
    validator.isEmpty(businessTaxData.businessTaxId) &&
    'Business Tax ID is required';
  errors.locationId = validator.isEmpty(businessTaxData.locationId)
    ? 'Location ID is required'
    : !validator.isNumeric(businessTaxData.locationId) &&
      'Location ID must be a number';
  errors.businessType =
    validator.isEmpty(businessTaxData.businessType) &&
    'Business Type is required';
  errors.classification =
    validator.isEmpty(businessTaxData.classification) &&
    'Classification is required';

  for (const key in errors) {
    if (
      errors[key] === null ||
      errors[key] === undefined ||
      errors[key] === false
    ) {
      delete errors[key];
    }
  }
  return errors;
};

export const validatePayrollData = (clientPayrollData) => {
  const errors: any = {};
  console.log(clientPayrollData);

  errors.eftpsPin =
    validator.isEmpty(clientPayrollData.eftpsPin) && 'EFTPS PIN is required';
  errors.withHoldingNumber =
    validator.isEmpty(clientPayrollData.withHoldingNumber) &&
    'Withholding # PIN is required';
  errors.laborAccNumber =
    validator.isEmpty(clientPayrollData.laborAccNumber) &&
    'Labor A/c # PIN is required';
  // errors.payrollSchedule =
  //   !(clientPayrollData.payrollSchedule.length() > 0) &&
  //   'Payroll Schedule PIN is required';
  // errors.payrollDeliveryMethod =
  //   validator.isEmpty(clientPayrollData.payrollDeliveryMethod) &&
  //   'Payroll Deilvery Method is required';
  // errors.payrollProcessingDay =
  //   validator.isEmpty(clientPayrollData.eftpsPin) &&
  //   'Payroll Processing Day is required';

  for (const key in errors) {
    if (
      errors[key] === null ||
      errors[key] === undefined ||
      errors[key] === false
    ) {
      delete errors[key];
    }
  }
  return errors;
};

export const validateOwnershipData = (clientOwnershipData) => {
  const errors: any = {};

  errors.ssnFein =
    validator.isEmpty(clientOwnershipData.ssnFein) && 'SSN Or FEIN is required';
  errors.fullLegalName =
    validator.isEmpty(clientOwnershipData.fullLegalName) &&
    'Full Legal Nameis required';
  errors.title =
    validator.isEmpty(clientOwnershipData.title) && 'Title is required';
  errors.type =
    validator.isEmpty(clientOwnershipData.type) && 'Type is required';
  errors.ownershipPercent = validator.isEmpty(
    clientOwnershipData.ownershipPercent
  )
    ? 'Ownership Percentage is required'
    : (!validator.isNumeric(clientOwnershipData.ownershipPercent) &&
        'Ownership Percentage must be a number') ||
      (+clientOwnershipData.ownershipPercent > 100 &&
        'Ownership Percentage should not be greater than 100');

  for (const key in errors) {
    if (
      errors[key] === null ||
      errors[key] === undefined ||
      errors[key] === false
    ) {
      delete errors[key];
    }
  }
  return errors;
};
