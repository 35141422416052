// // src/routes.ts
// import { RouteObject } from 'react-router';
// import Overview from 'src/content/overview';
// import Crypto from 'src/content/dashboards/Crypto';
// import Messenger from 'src/content/applications/Messenger';
// import Transactions from 'src/content/applications/Transactions';
// import UserProfile from 'src/content/applications/Users/profile';
// import UserSettings from 'src/content/applications/Users/settings';
// import Buttons from 'src/content/pages/Components/Buttons';
// import Modals from 'src/content/pages/Components/Modals';
// import Accordions from 'src/content/pages/Components/Accordions';
// import Tabs from 'src/content/pages/Components/Tabs';
// import Badges from 'src/content/pages/Components/Badges';
// import Tooltips from 'src/content/pages/Components/Tooltips';
// import Avatars from 'src/content/pages/Components/Avatars';
// import Cards from 'src/content/pages/Components/Cards';
// import Forms from 'src/content/pages/Components/Forms';
// import Status404 from 'src/content/pages/Status/Status404';
// import Status500 from 'src/content/pages/Status/Status500';
// import StatusComingSoon from 'src/content/pages/Status/ComingSoon';
// import StatusMaintenance from 'src/content/pages/Status/Maintenance';

// const routes: RouteObject[] = [
//   {
//     path: '/',
//     element: <Overview />,
//   },
//   {
//     path: '/dashboards',
//     children: [
//       { path: 'crypto', element: <Crypto /> },
//       { path: 'messenger', element: <Messenger /> },
//     ],
//   },
//   {
//     path: '/management',
//     children: [
//       { path: 'transactions', element: <Transactions /> },
//       {
//         path: 'profile',
//         children: [
//           { path: 'details', element: <UserProfile /> },
//           { path: 'settings', element: <UserSettings /> },
//         ],
//       },
//     ],
//   },
//   {
//     path: '/components',
//     children: [
//       { path: 'buttons', element: <Buttons /> },
//       { path: 'modals', element: <Modals /> },
//       { path: 'accordions', element: <Accordions /> },
//       { path: 'tabs', element: <Tabs /> },
//       { path: 'badges', element: <Badges /> },
//       { path: 'tooltips', element: <Tooltips /> },
//       { path: 'avatars', element: <Avatars /> },
//       { path: 'cards', element: <Cards /> },
//       { path: 'forms', element: <Forms /> },
//     ],
//   },
//   {
//     path: '/status',
//     children: [
//       { path: '404', element: <Status404 /> },
//       { path: '500', element: <Status500 /> },
//       { path: 'maintenance', element: <StatusMaintenance /> },
//       { path: 'coming-soon', element: <StatusComingSoon /> },
//     ],
//   },
//   {
//     path: '*',
//     element: <Status404 />,
//   },
// ];

// export default routes;

// src/router.ts
import { RouteObject } from 'react-router';
import HomePage from './components/HomePage/homepage';
import Clients from './components/ClientsPage/clients';
import Sales from './components/SalesPage/sales';
import Reports from './components/Reports/reports';
import SalesTaxReport from './components/Reports/salesTaxReport';
import ClientsForm from './components/ClientsForm/clientsForm';
import Employees from './components/EmployeesPage/employees';
import PrintView from './components/viewClient/printView';
import Payroll from './components/Payroll/payroll';
import PayrollRunDayReport from './components/Reports/payrollRunDayReport';
import SalesReport from './components/Reports/salesReport';
import PayrollReport from './components/Reports/payrollReport';
import EmployeeReport from './components/Reports/employeeReport';
import OwnershipReport from './components/Reports/ownershipReport';
import ClientDetails from './components/Reports/clientReport';
import Todo from './components/Todos/todo';
import PayrollStatus from './components/Reports/payrollStatus';
let routes: RouteObject[] = [];
const user = JSON.parse(localStorage.getItem('user') || 'null');
const adminRoutes = [
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/employees',
    element: <Employees />
  },
  {
    path: '/clients',
    element: <Clients />
  },
  {
    path: '/sales',
    element: <Sales />
  },
  {
    path: '/payroll',
    element: <Payroll/>
  },
  {
    path: '/reports',
    element: <Reports />
  },
  {
    path: '/todo',
    element: <Todo/>
  },
  {
    path: '/salesTaxReport',
    element: <SalesTaxReport />
  },
  {
    path: '/salesReport',
    element: <SalesReport />
  },
  {
    path: '/payrollRunDayReport',
    element: <PayrollRunDayReport />
  },
  {
    path: '/payrollReport',
    element: <PayrollReport />
  },
  {
    path: '/ownershipReport',
    element: <OwnershipReport />
  },
  {
    path: '/employeeReport',
    element: <EmployeeReport />
  },
  {
    path: '/clientsReport',
    element: <ClientDetails />
  },
  {
    path: '/payrollStatusReport',
    element: <PayrollStatus />},
  {
    path: '/print',
    element: <PrintView />
  }
  // {
  //   path: '/clientsForm',
  //   element: <ClientsForm />,
  // }
  // Add more routes as needed
];
const employeeRoutes = [
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/clients',
    element: <Clients />
  },
  {
    path: '/sales',
    element: <Sales />
  },
  {
    path: '/payroll',
    element: <Payroll/>
  },
  {
    path: '/reports',
    element: <Reports />
  }
];

if (user && user.role === 'admin') {
  console.log(user.role);

  routes = adminRoutes;
} else if (user && user.role === 'semiAdmin') {
  console.log(user.role);

  routes = employeeRoutes;
}

export default routes;
