import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';


const Chip = ({ icon: Icon, text, path = '#' }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          //   backgroundColor: '#6db8ff',
          backgroundColor: '#1976d2',
          color: '#ffffff',
          // md:{width: '10rem', height: '10rem'},
          width:{xs: '7rem', sm: '8rem', md: '9rem'},
          height: {xs: '7rem', sm: '8rem', md: '9rem'},
          // xs:{width: '7rem', height: '6rem'},
          lg:{width: '10rem', height: '10rem'},
          borderRadius: '16%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            transform: 'scale(1.1)',
            transition: 'all 0.3s ease'
          },
          cursor: 'pointer',
          // p: 1
          my: 1
        }}
        onClick={() => navigate(`${path}`)}
      >
        <Box>
          {/* <ReceiptIcon sx={{ width: '3em', height: '3em' }} /> */}
          {Icon && <Icon sx={{ width: '2em', height: '2em' }} />}
        </Box>
        <Typography  sx={{ fontWeight: 600, textAlign: 'center', px: 1 }}>
          {text}
        </Typography>
      </Box>
    </>
  );
};

export default Chip;
