import { Grid, InputLabel } from '@mui/material'
import React from 'react'

const TextLabelSmall = ({text,htmlFor=""}) => {
  return (
    <Grid item xs={12} md={4} sx={{ xs: { mt: 0 }, md: { mt: 1 } }}>
    <InputLabel htmlFor={htmlFor}>
    {text}
    </InputLabel>
  </Grid>
  )
}

export default TextLabelSmall