import { Grid, InputAdornment, TextField } from '@mui/material'
import React from 'react'

const DoubleInput = ({ value1, value2, label1, label2, handleInputChange, validation, name1, name2, placeholder1, placeholder2 ,props = {}}) => {
  return (
    <Grid item xs={12} md={8}>
    <Grid container spacing={2}>
      <Grid item xs={6} md={3}>
        <TextField
          id={name1}
          placeholder={placeholder1}
          variant="outlined"
          fullWidth
          size="small"
          value={value1}
          label={label1}
          InputLabelProps={{ shrink: true }}
          onChange={(e) =>
            handleInputChange(name1, e.target.value)
          }
          error={!!validation.name1}
          helperText={validation.name1}
          InputProps={props}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          id={name2}
          placeholder={placeholder2}
          variant="outlined"
          fullWidth
          size="small"
          value={value2}
          label={label2}
          InputLabelProps={{ shrink: true }}
          onChange={(e) =>
            handleInputChange(name2, e.target.value)
          }
          error={!!validation.name2}
          helperText={validation.name2}
        InputProps={props}
        />
      </Grid>
    </Grid>
  </Grid>
  )
}

export default DoubleInput