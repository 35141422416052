import { Password } from '@mui/icons-material';
import { string } from 'prop-types';
import { API_BASE_URL } from 'src/constants/common';
import fetchWrapper from 'src/fetchWrapper';

export interface LoginResponse {
  meta: {
    status: number;
    message: string;
  };
  data: {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    api_token: string;
  };
}

export interface registerEmployee {
  meta: {
    status: number;
    message: string;
  };
  empData: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    api_token: string;
  };
}

export const authCheck = async () => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  if (user) {
    await fetchWrapper('/authCheck', { method: 'GET' });
    return;
  }
};

export const login = async (
  email: string,
  password: string,
  otp: number
): Promise<LoginResponse> => {
  console.log(email, password, otp);

  localStorage.setItem('lastActivityTime', Date.now().toString());
  const response = await fetch(`${API_BASE_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password, otp: otp })
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();
};

export const requestOtp = async (email: string, password: string) => {
  const response = await fetch(`${API_BASE_URL}/auth/requestOtp`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }
};

export const empRegister = async (empData: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}): Promise<registerEmployee> => {
  localStorage.setItem('lastActivityTime', Date.now().toString());
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const role = 'semiAdmin';
  const response = await fetchWrapper(`/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify({ ...empData, role })
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();
};
