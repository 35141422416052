import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from 'react-router-dom';

let pages = [];
const adminPages = ['Employees', 'Clients', 'Sales', 'Payroll', 'Reports'];
const employeePages = ['Clients', 'Sales', 'Payroll', 'Reports'];
const user = JSON.parse(localStorage.getItem('user') || 'null');
if (user && user.role === 'admin') {
  pages = adminPages;
} else if (user && user.role === 'semiAdmin') {
  pages = employeePages;
}

const settings = ['Logout'];

interface NavbarProps {
  onLogout: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onLogout }) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  console.log({ user });
  const navigate = useNavigate();
  const handleLogout = () => {
    // Call the onLogout function when the logout button is clicked
    onLogout();
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const handleNavMenuItemClick = (page: string) => {
  //   handleCloseNavMenu();
  //   if (page === 'Logout') {
  //     handleLogout();
  //   }
  //   switch (page) {
  //     case 'clients':
  //       history.push('/clients');
  //   }
  // }
  const handlePageClick = (page: any) => {
    handleCloseNavMenu();

    switch (page) {
      case 'Employees':
        navigate('/employees');
        break;

      case 'Clients':
        navigate('/clients');
        break;

      case 'Sales':
        navigate('/sales');
        break;

        case 'Payroll':
          navigate('/payroll');
          break;

      case 'Reports':
        navigate('/reports');
        break;

      // case "Todo's":
      //   navigate('/todo');
      //   break;

      // Add more cases for other pages if needed
    }
  };
  return (
    // <div>
    //   {/* Add your navigation bar elements here */}
    //   <button onClick={handleLogout}>Logout</button>
    //   {/* Add more navigation elements as needed */}
    // </div>
    <AppBar position="static" style={{ borderRadius: '16px' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Box for Web View Starts Here */}
          <Box
            sx={{
              width: '100%',
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {/* <AdbIcon/> */}
              <Typography
                variant="h5"
                noWrap
                component="a"
                onClick={() => navigate('/')}
                href="/"
                sx={{
                  mr: 2,
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none'
                }}
              >
                AK
              </Typography>
            </Box>

            <Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    onClick={() => handlePageClick(page)}
                    sx={{
                      my: 2,
                      color: 'white',
                      display: 'block',
                      '&:hover': {
                        transform: 'scale(1.2)'
                      }
                    }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  color: 'inherit',
                  textDecoration: 'none'
                }}
              >
                {user && user.firstName}
              </Typography>
              <Box>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={user && user.firstName}
                      src="../../../public/static/images/bussiness-man.png"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={
                        setting === 'Logout'
                          ? handleLogout
                          : handleCloseUserMenu
                      }
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          </Box>
          {/* Box for Web View Ends Here */}

          {/* Box for Mobile View Starts Here */}
          <Box
            sx={{
              width: '100%',
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handlePageClick(page)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {/* <AdbIcon/> */}
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                onClick={() => navigate('/')}
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none'
                }}
              >
                AK
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {' '}
              <Box>
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        alt={user && user.firstName}
                        src="../../../public/static/images/bussiness-man.png"
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem
                        key={setting}
                        onClick={
                          setting === 'Logout'
                            ? handleLogout
                            : handleCloseUserMenu
                        }
                      >
                        <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Box for Mobile View Ends Here */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
