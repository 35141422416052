import { Box } from '@mui/material';
import { authCheck } from 'src/services';
import logo from '../../Assets/Images/AK-4.png';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { getOwnershipReport } from 'src/services/ownership';

const HomePage = () => {
  authCheck();
  const [data, setData] = useState(null);





  return (
    <>
      {/* <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <img
          src={logo}
          alt="Ak fixTax Advisors"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            opacity: 0.6, // Decrease the opacity to 80%
            borderRadius: '10px' // Apply rounded border radius of 10px
          }}
        />
      </Box> */}

      {/* <ViewClient open={undefined} onClose={undefined}/> */}
    </>
  );
};

export default HomePage;
