import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
interface searchProps {
  columns: string[];
  onSearch: any;
  value: string;
  scolumns: string[];
  updateSearchValue: string;
  updateSelectedColumns: string[];
}

const SearchBarWithCheckbox = ({ columns, onSearch,value, scolumns,  updateSearchValue, updateSelectedColumns }: any) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(['Legal Business Name','Doing Business As']);
  const [searchValue, setSearchValue] = useState<string>('');


  const handleChange = (event: SelectChangeEvent<typeof scolumns>) => {
    const {
      target: { value },
    } = event;
    setSelectedColumns(typeof value === 'string' ? value.split(',') : value);
    updateSelectedColumns(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSearchChange =(event: React.ChangeEvent<HTMLInputElement>) => {
    try{
      const value = event.target.value;
      console.log("sv::",value);
      
      setSearchValue(value);
      updateSearchValue(value);
      // onSearch(selectedColumns, value);
  }catch(e:any){}
};

useEffect(() => {
  setSearchValue(value);
  onSearch(selectedColumns, searchValue);
}, [value]);



  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} md={4}>
        <FormControl fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">Select Columns</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedColumns}
            onChange={handleChange}
            input={<OutlinedInput label="Select Columns" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            size='small'
          >
            {columns.map((column) => (
              <MenuItem key={column.id} value={column.label}>
                <Checkbox checked={selectedColumns.indexOf(column.label) > -1} />
                <ListItemText primary={column.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <FormControl fullWidth>
          <InputLabel htmlFor="search-input">Search</InputLabel>
          <OutlinedInput
            label ="search"
            value={searchValue}
            onChange={handleSearchChange}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            size='small'
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default SearchBarWithCheckbox;
