// store.ts
import { configureStore } from '@reduxjs/toolkit';
import myReducer from './reducers/myReducer'; // Update the path

const store = configureStore({
  reducer: {
    myReducer,
  },
});

export default store;
