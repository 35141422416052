import React from 'react'
import { FormControlLabel, Grid, Checkbox} from '@mui/material'

const CheckBox = ({label,checked,handleInputChange,name}) => {
  return (
    <Grid item xs={12} md={2}>
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e) =>
            handleInputChange(name, e.target.checked)
          }
          color="primary"
          sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
        />
      }
      label={label}
    />
  </Grid>
  )
}

export default CheckBox