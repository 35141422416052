import fetchWrapper, { setNavigateCallback } from '../fetchWrapper';

export interface updateEmployee {
  meta: {
    status: number;
    message: string;
  };
  data: {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    api_token: string;
  };
}

//get API employee

export const getAllEmployees = async () => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  try {
    const response = await fetchWrapper(`/profile/employees`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const data = await response.json();
    return data; // Return the fetched data
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
    throw error; // Re-throw the error to be handled by the caller if necessary
  }
};

export const updateStatus = async (id: number, status: boolean) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const response = await fetchWrapper(`/profile/?id=${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify({ status })
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();
};

export const editEmployee = async (id: number, data) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  console.log('edit emp', data);

  if (data.password === '') {
    delete data.password;
  }
  const updatePayload = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: data.password
  };
  console.log({ updatePayload });

  delete data.status;
  const response = await fetchWrapper(`/profile/?id=${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  console.log(response);

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();
};

export const deleteEmployee = async (id: number) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const response = await fetchWrapper(`/profile/?id=${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify({ isDeleted: true })
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();
};
