import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BarLoader } from 'react-spinners';
import { NotificationManager } from 'react-notifications';
import {
  payrollDeliveryMethods,
  payrollProcessingDays,
  payrollSchedules,
  states
} from 'src/constants/clients';
import { formatClientId } from 'src/utils/helperFunctions';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import { getPayrollClients } from 'src/services/clientService';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getAllEmployees } from 'src/services/employeeService';
interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'clientId', label: 'Client ID' },
  { id: 'state', label: 'State' },
  { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 200 },
  {
    id: 'doingBusinessAs',
    label: 'Doing Business As',
    minWidth: 130
  },
  {
    id: 'ProcessingDay',
    label: 'Processing Day',
    minWidth: 90
  },
  {
    id: 'payrollSchedule',
    label: 'Schedule',
    minWidth: 100
  },
  {
    id: 'deliveryMethod',
    label: 'Delivery Method',
    minWidth: 90
  },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 70
  },
  {
    id: 'contactPerson',
    label: 'Contact Person',
    minWidth: 80
  },
  {
    id: 'contactEmail',
    label: 'Contact Email',
    minWidth: 60
  },
  {
    id: 'contactNumber',
    label: 'Contact Number',
    minWidth: 100
  },
  {
    id: 'accountantName',
    label: 'Accountant',
    minWidth: 80
  },
  {
    id: 'notes',
    label: 'Notes',
    minWidth: 150
  }
];
const PayrollRunDayReport = () => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ProcessingDay, setProcessingDay] = useState(
    moment(new Date()).format('dddd')
  );
  const [payrollSchedule, setPayrollSchedule] = useState('');
  const [payrollDeliveryMethod, setPayrollDeliveryMethod] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const styles = {
    tableCell:{ border: '1px solid #f1f1f1', padding: 1 },
    ecxelSheetCell:{ border: '1px solid #f1f1f1', padding: 1 }
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleFilterChange = () => {
    if((ProcessingDay==='' || ProcessingDay === null )&& (payrollSchedule==='' || payrollSchedule === null) && (payrollDeliveryMethod==='' || payrollDeliveryMethod === null) && (selectedEmployee==='' || selectedEmployee === null) && (selectedState==='' || selectedState === null) && searchValue===''  ){
      setFilteredRows(rows);
      return
    }
    console.log({rows});
    
    const filtered = rows.filter((row: any) => {
      console.log(row.accountantName, selectedEmployee);
      
      const matchProcessingDay = ProcessingDay === '' || ProcessingDay === null || row.payrolls?.payrollProcessingDay === ProcessingDay;
      const matchPayrollSchedule = payrollSchedule === '' || payrollSchedule === null || row.payrolls?.payrollSchedule === payrollSchedule;
      const matchPayrollDeliveryMethod = payrollDeliveryMethod === '' || payrollDeliveryMethod === null || row.payrolls?.payrollDeliveryMethod === payrollDeliveryMethod;
      const matchSearchValue = searchValue === '' || 
      (row.legalBusinessName && row.legalBusinessName.toString().toLowerCase().includes(searchValue.toLowerCase())) ||
      (row.doingBusinessAs && row.doingBusinessAs.toString().toLowerCase().includes(searchValue.toLowerCase()));
      const matchEmployee = selectedEmployee === '' || selectedEmployee === null || row.accountantName === selectedEmployee;
      const matchState = selectedState === '' || selectedState === null || row.state === selectedState;

  
      return matchProcessingDay && matchPayrollSchedule && matchPayrollDeliveryMethod && matchSearchValue && matchEmployee && matchState;
    });

    console.log({ filtered });
    
    setFilteredRows(filtered);
    
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getClients = async () => {
    try {
      setLoading(true);
      const data = await getPayrollClients();
      if(data.meta.status === 200){
        setRows(data.data);
        handleFilterChange()
      }
      console.log('data::', data);

      // Create an array of Data objects using the fetched data
      // const clientRows = data.data.map((item: any) => {
      //   return createData(item);
      // });

      // Update the rows state with the new data
      // setRows(clientRows);
      // console.log({ clientRows });

      // setUnFilteredClient(clientRows);

      setLoading(false); // Set loader to false after successful fetching
    } catch (error) {
      setLoading(false); // Ensure loader is set to false on error
      NotificationManager.error('Error fetching Data:', 'Error', 3000);
      console.error('Error fetching clients:', error);
    }
  };
  const getEmployees = async () => {
    try {
      setLoading(true);
      const data = await getAllEmployees();
      if(data.meta.status === 200){
        console.log('data::', data);
        
        setEmployees(data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching employees:', error);
  }}
  const handleClearFilter = () => {
    setProcessingDay('')
    setPayrollSchedule('')
    setPayrollDeliveryMethod('')
    setSearchValue('')
    setSelectedEmployee('')
    setSelectedState('')
  }

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Define columns
    worksheet.columns = [
      { header: 'Client Id', key: 'id', width: 6 },
      { header: 'State', key: 'state', width: 30 },
      { header: 'Legal Business Name', key: 'legalBusinessName', width: 30 },
      { header: 'Doing Business As', key: 'doingBusinessAs', width: 30 },
      { header: 'Processing day', key: 'payrollProcessingDay', width: 12 },
      { header: 'Schedule', key: 'payrollSchedule', width: 15 },
      { header: 'Delivery Method', key: 'payrollDeliveryMethod', width: 15},
      { header: 'Start Date', key: 'startDate', width: 10 },
      { header: 'Contact person', key: 'contactPerson', width: 18 },
      { header: 'Contact Email', key: 'contactEmail', width: 18 },
      { header: 'Contact Number', key: 'contactNumber', width: 18},
      { header: 'Accountant', key: 'accountant', width: 20 },
      { header: 'Notes', key: 'notes', width: 60 },
    ];


    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' }, // Yellow background
    };
    // Add rows
    filteredRows.forEach(row => {
      worksheet.addRow({
        id:formatClientId(+row.id),
        state: row.state,
        legalBusinessName: row.legalBusinessName,
        doingBusinessAs: row.doingBusinessAs,
        payrollProcessingDay: row.payrolls?.payrollProcessingDay,
        payrollSchedule: row.payrolls?.payrollSchedule,
        payrollDeliveryMethod: row.payrolls?.payrollDeliveryMethod,
        startDate:row.payrolls?.payrollStartDate? moment(row.payrolls?.payrollStartDate).format(
          'MM-DD-YY'
        ) :'',
        contactPerson: row.payrolls?.contactName || '',
        contactEmail: row.payrolls?.contactEmail || '',
        contactNumber: row.payrolls?.contactNumber || '',
        accountant:row.accountantName || '',
        notes: row.payrolls?.importantNotes || '',
      });
    });

    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'export.xlsx');
  };


  useEffect(() => {
    getClients()
    getEmployees()
  },[]);
  useEffect(() => {
    handleFilterChange()
  },[rows,payrollSchedule, ProcessingDay, payrollDeliveryMethod, searchValue, selectedEmployee, selectedState]);

  return (
    <>
      <Box my={2} mx={5}>
        {loading && (
          <div className="loader-wrapper" style={{ textAlign: 'center' }}>
            <BarLoader color={'#1976D2'} loading={loading} width={'94vw'} />
          </div>
        )}
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#1976D2',
            fontSize: '1.5em',
            my: 2
          }}
        >
          Payroll Run Day Report
        </Typography>
        <Grid container spacing={2} mt={0}>
          <Dropdown
            value={ProcessingDay}
            label="Payroll Processing Day"
            placeholder=""
            handleInputChange={setProcessingDay}
            validationField={''}
            name="payrollProcessingDay"
            options={payrollProcessingDays}
          />
          <Dropdown
            value={payrollSchedule}
            label="Payroll Schedule"
            placeholder=""
            handleInputChange={setPayrollSchedule}
            validationField={''}
            name="payrollSchedule"
            options={payrollSchedules}
          />
          <Dropdown
            value={payrollDeliveryMethod}
            label="Payroll Delivery Method"
            placeholder=""
            handleInputChange={setPayrollDeliveryMethod}
            validationField={''}
            name="payrollDeliveryMethod"
            options={payrollDeliveryMethods}
          />
          <Dropdown
            value={selectedEmployee}
            label="Accountant Name"
            placeholder=""
            handleInputChange={setSelectedEmployee}
            validationField={''}
            name="accountantName"
            options={employees.map((emp)=>emp.firstName + ' ' + emp.lastName)}
          />
          <Dropdown
            value={selectedState}
            label="State"
            placeholder=""
            handleInputChange={setSelectedState}
            validationField={''}
            name="state"
            options={['All', ...states]}
          />
            {/* <InputLabel htmlFor="state">State</InputLabel>
              <Autocomplete
                id="state"
                options={['All', ...states]}
                // getOptionLabel={(client) => client.legalBusinessName || ''}
                value={selectedState}
                onChange={(_, newValue) => handleStateChange(newValue)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              /> */}
        </Grid>
      </Box>

      <Grid mx={3} my={2} width={'inherit'} container spacing={2}>
        <Grid item md={6}>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={10} md={10}>
          <FormControl fullWidth>
            <TextField
              value={searchValue}
              id="search-input"
              label="Search"
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
              size="small"
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={2} md={2}>
          <Button
            variant="contained"
            color="warning"
            onClick={handleClearFilter}
          >
            Clear
          </Button>
        </Grid>
        </Grid>
       
        </Grid>
        

        <Grid item md={6}>
        <Grid container justifyContent="flex-end">
        <Grid item xs={3} sm={3} md={3}>
          <Button
            variant="contained"
            color="success"
            onClick={handleExportToExcel}
          >
            Export
            <FileDownloadIcon/>
          </Button>
        </Grid>
        </Grid>  
        </Grid>
        
      </Grid>
      <Box sx={{ mx: 5, width: 'inherit', overflow: 'hidden' }}>
        <Paper sx={{ width: '100%', overflow: 'auto' }} elevation={3}>
          <TableContainer style={{ marginLeft: '0px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: '800',
                        color: '#1976D2',
                        border: '1px solid #E3E1D9',
                        borderCollapse: 'collapse',
                        padding: 5
                      }}
                    >
                      <Typography>{column.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell sx={styles.tableCell}>
                          {formatClientId(+row.id)}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {row.state}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {row.legalBusinessName}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {row.doingBusinessAs}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {row.payrolls?.payrollProcessingDay || ''}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {row.payrolls?.payrollSchedule || ''}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {row.payrolls?.payrollDeliveryMethod || ''}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {(row.payrolls?.payrollStartDate &&
                            moment(row.payrolls?.payrollStartDate).format(
                              'MM-DD-YY'
                            )) ||
                            ''}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {row.payrolls?.contactName || ''}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {row.payrolls?.contactEmail || ''}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {row.payrolls?.contactNumber || ''}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {row.accountantName || ''}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {row.payrolls?.importantNotes || ''}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100, 150, 200]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
};

export default PayrollRunDayReport;

const Dropdown = ({
  value,
  label,
  handleInputChange,
  validationField,
  name,
  placeholder,
  options,
  props = {}
}) => {
  return (
    <Grid item xs={6} md={2}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Autocomplete
        id={name}
        options={options}
        value={value || ''}
        onChange={(_, newValue) => handleInputChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            size="small"
            error={!!validationField}
            helperText={validationField}
          />
        )}
      />
    </Grid>
  );
};
