// reducers/myReducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MyReducerState {
  value: string;
}

const initialState: MyReducerState = {
  value: '',
};

const mySlice = createSlice({
  name: 'myReducer',
  initialState,
  reducers: {
    updateValue: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const { updateValue } = mySlice.actions;
export const selectValue = (state: { myReducer: MyReducerState }) => state.myReducer.value;
export default mySlice.reducer;
