// src/components/Auth/Login.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  TextField,
  Button,
  Container,
  CssBaseline,
  Typography,
  Box,
  Alert,
  IconButton,
  InputAdornment
} from '@mui/material';
import { login, LoginResponse, requestOtp } from '../../services/authService';
import { BarLoader } from 'react-spinners';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { NotificationManager } from 'react-notifications';
import OTPModal from '../otpModal';
interface LoginForm {
  email: string;
  password: string;
  otp?: number;
}

interface LoginProps {
  onLogin: () => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<LoginForm>();
  const [showPassword, setShowPassword] = useState(false);
  const [loginData, setLoginData] = useState<LoginForm>({
    email: '',
    password: ''
  });

  const loginFunction = async (data: LoginForm) => {
    console.log(data);

    try {
      setLoading(true);
      const response: LoginResponse = await login(
        data.email,
        data.password,
        +data.otp
      );
      console.log('response::', response);
      NotificationManager.success(`Login Successful`, 'Success', 3000);
      localStorage.setItem('user', JSON.stringify(response.data));
      onLogin();
      navigate('/');
      window.location.reload();
      // return response;
      setLoading(false);
    } catch (error) {
      setLoading(false);
      NotificationManager.error(`Invalid OTP!`, 'Error', 3000);
      console.error('Login failed:', error.message);
      console.error('Login failed:', error.response.data);
    }
  };

  const requestOTP = async (data: LoginForm) => {
    try {
      const response: any = await requestOtp(data.email, data.password);
      setShowOTPModal(true);
    } catch (error) {
      console.error('Login failed:', error.message);
      console.error('Login failed:', error.response.data);
    }
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      setLoading(true);
      setLoginData(data);
      const response: any = await requestOTP(data);
      setShowOTPModal(true);
      setLoading(false);
    } catch (error) {
      console.error('Login failed:', error.message);
      setError('password', { message: 'Invalid email or password' });
      setLoading(false);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh'
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        style={{
          textAlign: 'center',
          border: '1px solid #ddd',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          padding: '20px',
          borderRadius: '8px',
          marginLeft: '15px',
          marginRight: '15px'
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              mt: 2,
              mb: 3
            }}
            onClick={() => navigate('/')}
          >
            <span>
              {' '}
              <span>
                <strong
                  style={{
                    color: '#1565C0',
                    fontSize: '30px',
                    fontFamily: 'Roboto'
                  }}
                >
                  AK
                </strong>{' '}
                <br />
              </span>
              FinTax Advisors
            </span>
          </Box>

          {/* <Typography component="h1" variant="h5">
          Login
        </Typography> */}
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              label="Email"
              type="email"
              {...register('email', { required: 'Email is required' })}
              fullWidth
              margin="normal"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            {/* <TextField
            label="Password"
            type="password"
            {...register('password', { required: 'Password is required' })}
            fullWidth
            margin="normal"
            error={!!errors.password}
            helperText={errors.password?.message}
          /> */}
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              {...register('password', { required: 'Password is required' })}
              fullWidth
              margin="normal"
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{
                '& input:-webkit-autofill': {
                  transitionDelay: '9999s' // a very long delay to prevent autofill styles from being applied
                },
                '& input:-webkit-autofill:focus': {
                  transitionDelay: '9999s'
                },
                '& input:-webkit-autofill:hover': {
                  transitionDelay: '9999s'
                },
                '& input:-webkit-autofill:active': {
                  transitionDelay: '9999s'
                },
                '& input:-internal-autofill-selected': {
                  backgroundColor: 'white !important'
                }
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, height: 40 }}
            >
              {loading ? (
                <BarLoader
                  color="#ffffff"
                  loading={loading}
                  height={4}
                  width={150}
                />
              ) : (
                'Log in'
              )}
            </Button>
          </Box>
          {errors.password && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errors.password.message}
            </Alert>
          )}
        </Box>
      </Container>
      <OTPModal
        open={showOTPModal}
        onClose={() => setShowOTPModal(false)}
        onSubmit={(otp) => loginFunction({ ...loginData, otp: +otp })}
        onResend={() => requestOtp(loginData.email, loginData.password)}
      />
    </Box>
  );
};

export default Login;
