import { API_BASE_URL } from 'src/constants/common';
import fetchWrapper from 'src/fetchWrapper';
import { AddSales, IAddSales } from 'src/interface/sales';

export const addSales = async (data: IAddSales): Promise<AddSales> => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  console.log(data);

  const response = await fetchWrapper(`/sales/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();
};

export const salesEmail = async (data: {
  clientId: number;
  month: string;
  year: number;
}) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  console.log(data);

  const response = await fetchWrapper(`/sales/upcomingSalesEmail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();
};
export const salesReminderEmail = async (data: {
  clientId: number;
  month: string;
  year: number;
  legalName: string;
  doingBusinessAs: string;
  email: string;
}) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  console.log(data);

  const response = await fetchWrapper(`/sales/salesReportReminderEmail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.api_token
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.meta.message);
  }

  return response.json();
};

export const getAllSales = async (startDate: string = '', endDate: string = '') => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/sales/?startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching Sales:', error);
  }

  if (!response.ok) {
    throw new Error('Failed to fetch Sales');
  }

  const data = await response.json();

  return data;
};
export const getSaleById = async (id:number) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/sales/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching Sales:', error);
  }

  if (!response.ok) {
    throw new Error('Failed to fetch Sales');
  }

  const data = await response.json();

  return data;
};

export const getPreviousSales = async (payload: {
  year: number;
  month: string;
  clientId: number;
}) => {
  console.log(payload);
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(
      `/sales/${payload.clientId}/previousMonth?year=${payload.year}&month=${payload.month}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: user.api_token
        }
      }
    );
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching Previous Sale:', error);
  }

  if (!response.ok) {
    throw new Error('Failed to fetch Previous Sale');
  }

  const data = await response.json();

  return data;
};

export const deleteSales = async (id: number) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  let response: any;
  try {
    response = await fetchWrapper(`/sales/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
  }
};

export const getSaleTaxReport = async (payload:{clientId: number, monthStart: string, monthEnd: string}): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const { clientId, monthStart, monthEnd } = {
    clientId: payload.clientId,
    monthStart: payload.monthStart,
    monthEnd: payload.monthEnd};
  let response: any;
  try {
    response = await fetchWrapper(`/sales/salesByparams/${clientId}/${monthStart}/${monthEnd}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.api_token
      }
    });
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching data:', error);
  }

  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }

  const data = await response.json();

  return data;
}