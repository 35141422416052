import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';


const PrintView = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.print()
        navigate('/clients');
    })
  return (
    <div>PrintView</div>
  )
}

export default PrintView