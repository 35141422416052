import { Autocomplete, Grid, InputLabel, TextField } from '@mui/material'
import React from 'react'

const Dropdown = ({ value, label, handleInputChange, validationField, name, placeholder, options,props = {} }) => {
    
    return (
      <Grid item xs={12} md={4}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Autocomplete
          id={name}
          options={options}
          value={value || ''}
          onChange={(_, newValue) =>
            handleInputChange(name, newValue)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              variant="outlined"
              size="small"
              error={!!validationField}
              helperText={validationField}
            />
          )}
        />
      </Grid>
    );
  };
export default Dropdown